import http from "./httpService";
import auth from "./authService";
const apiEndpoint = "/kids";
const reportEndPoint = "/reports";
const diaryEndPoint = "/diaries";

http.setJwt(auth.getJwt());

const Utils = class {
    createKid = async (data, userID) => {
        // http.removeXHeaders();
        if (userID) http.setUserIDHeader(userID);
        return await http.post(apiEndpoint, data);
    };

    getKids = async userID => {
        // http.removeXHeaders();
        if (userID) http.setUserIDHeader(userID);
        return await http.get(apiEndpoint);
    };
    getKid = async kidID => {
        // http.removeXHeaders();
        return await http.get(apiEndpoint + "/" + kidID);
    };

    joinClass = async data => {
        return await http.post(apiEndpoint + "/joinClass", data);
    };
};

export default new Utils();
