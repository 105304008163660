import $ from "jquery";
import React, { Component } from "react";
import Form from "./commons/form";
import Joi from "joi-browser";
import auth from "../services/authService";
import ClientJS from "clientjs";

const client = new ClientJS();

function getDeviceName() {
    const browser = client.getBrowser();
    const os = client.getOS();

    return `${browser.name} ${browser.version} On ${os.name} ${os.version}`;
}

class Login extends Form {
    state = {
        data: {
            email: "",
            password: ""
            // deviceName: getDeviceName()
        },
        errors: {}
    };
    schema = {
        email: Joi.string()
            .email({ minDomainAtoms: 2 })
            .required()
            .label("Email"),
        password: Joi.string()
            .required()
            .label("Password")
    };
    doSubmit = async () => {
        try {
            const { data } = this.state;
            await auth.classRoomlogin(
                data.email,
                data.password,
                data.deviceName
            );
            const { state } = this.props.location;

            window.location = state
                ? state.from.pathname + state.from.search
                : "/";
        } catch (ex) {
            console.log(ex);
        }
    };
    componentDidMount = () => {
        const comp = this;
        console.log(this.props.location);
        $("#loginForm").keypress(function(event) {
            var keycode = event.keyCode ? event.keyCode : event.which;
            if (keycode == "13") {
                comp.handleSubmit(new Event(""));
            }
        });
    };
    componentWillUnmount = () => {
        $("#loginForm").unbind();
    };
    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    <img
                        className="img-fluid"
                        src={
                            process.env.REACT_APP_RES_URL +
                            "portal-images/drAtDoor.png"
                        }
                    />
                </div>
                <div className="col-md-6">
                    <form onSubmit={this.handleSubmit} id="loginForm">
                        {this.renderInput("email", "Email")}
                        {this.renderInput("password", "Password", "password")}
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;
