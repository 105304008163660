import http from "./httpService";
import auth from "./authService";

const endpoint = "/assignments";

http.setJwt(auth.getJwt());

const Utis = class {
    getAssignments = async data => {
        for (let p in data) {
            if (data[p] === "" || data[p] === null) delete data[p];
        }

        return await http.get(endpoint + "?filter=" + JSON.stringify(data));
    };

    getAssignment = async id => {
        return await http.get(endpoint + "/" + id);
    };

    getLatenessTest = async classID => {
        return await http.get(endpoint + "/latenessTest/" + classID);
    };

    createAssignment = async data => {
        return await http.post(endpoint, data);
    };

    updateAssignment = async data => {
        return await http.put(endpoint + "/" + data._id, data);
    };

    deleteAssignment = async id => {
        return await http.delete(endpoint + "/" + id);
    };
};

export default new Utis();
