import React, { Component } from "react";
import moment from "moment";

class SiteFooter extends Component {
    state = {};
    render() {
        return (
            <footer id="siteFooter" className="row">
                <div className="col">
                    <a href="#">Terms of Use</a>
                    <a href="#">Privacy Policy</a>
                    <span>
                        ©{moment().format("YYYY")} Tulip Cultural Interactive
                        Media Ltd. All Rights Reserved.
                    </span>
                </div>
            </footer>
        );
    }
}

export default SiteFooter;
