import React, { Component } from "react";
import { Link } from "react-router-dom";
class NotFound extends Component {
    state = {};
    render() {
        return (
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col">
                        <div
                            className="jumbotron"
                            style={{ background: "none" }}
                        >
                            <h1 className="mb-4">404!</h1>
                            <p className="lead" style={{ fontSize: "18pt" }}>
                                Sorry, page not found
                            </p>

                            <p className="lead">
                                <Link
                                    className="btn btn-primary btn-lg mt-2"
                                    to="/"
                                    role="button"
                                >
                                    Back to Home
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
