import React, { Component } from "react";
import { Table, Modal, Button } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import assignmentService from "../../services/assignmentService";

class AssignmentTbl extends Component {
    state = {
        assignments: []
    };
    async componentDidMount() {
        await this.setUpData();
    }
    setUpData = async () => {
        const { classID } = this.props;
        if (!classID) return;

        try {
            const { data } = await assignmentService.getAssignments({
                gClass: classID
            });
            this.setState({
                assignments: data
            });
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        const { classID } = this.props;
        return <React.Fragment>{this.renderAssignmentTbl()}</React.Fragment>;
    }

    renderAssignmentTbl = () => {
        const { classID } = this.props;
        const { assignments } = this.state;

        const columns = [
            { title: "Name", dataIndex: "name" },
            {
                title: "Due Date",
                dataIndex: "dueDate",
                render: (v, r) => {
                    if (v) return moment(v).format("YYYY-MM-DD");
                }
            },
            {
                title: "Is Active",
                dataIndex: "isActive",
                render: (v, r) => {
                    if (v) return "Yes";
                }
            },
            {
                title: "Is a tTest",
                dataIndex: "isTest",
                render: (v, r) => {
                    if (v) return "Yes";
                }
            },
            {
                title: "",
                render: (v, r) => {
                    return (
                        <div>
                            <Link
                                className="btn btn-sm btn-primary "
                                to={`/assignments/${r._id}?classID=${classID}`}
                            >
                                View Result
                            </Link>
                        </div>
                    );
                }
            }
        ];

        return (
            <div>
                <Table
                    dataSource={assignments}
                    columns={columns}
                    pagination={true}
                    bordered={true}
                    rowKey="_id"
                />
            </div>
        );
    };
}

export default AssignmentTbl;
