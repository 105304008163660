import React, { Component } from "react";
import { Link } from "react-router-dom";

class UnitBox extends Component {
    state = {};
    hasContent = materials => {
        if (materials && Array.isArray(materials) && materials.length > 0)
            return true;
        return false;
    };
    hasReadingIntro = material => {
        if (
            material &&
            material.lesson === "reading" &&
            material.intro &&
            Array.isArray(material.intro) &&
            material.intro.length > 0 &&
            material.intro[0].contents.length > 0
        )
            return true;

        return false;
    };
    render() {
        const { unit, classID, materials } = this.props;
        if (!unit) return "";
        const phonic = materials.find(m => m.lesson === "phonic");
        const spelling = materials.find(m => m.lesson === "spelling");
        const grammar = materials.find(m => m.lesson === "grammar");
        const reading = materials.find(m => m.lesson === "reading");
        const writing = materials.find(m => m.lesson === "writing");
        const lessons = [phonic, spelling, grammar, reading, writing];

        if (!this.hasContent(materials)) return "";
        return (
            <div className="unitBox">
                <div className="head row">
                    <div className="lead col-sm-12">Unit {unit}</div>
                </div>
                <hr />
                <div className="body row">
                    {!this.hasContent(materials) && (
                        <div className="col">
                            <span className="lead">Coming Soon!</span>
                        </div>
                    )}
                    {this.hasContent(materials) && (
                        <React.Fragment>
                            {lessons.map((lesson, index) => {
                                if (!lesson) return "";
                                let iconCls = "fa fa-microphone";
                                let pageName = "lesson";
                                if (lesson.lesson === "spelling") {
                                    iconCls = "fa fa-graduation-cap";
                                } else if (lesson.lesson === "grammar") {
                                    iconCls = "fa fa-file-text-o";
                                } else if (lesson.lesson === "reading") {
                                    iconCls = "fa fa-book";
                                    pageName = "reading";
                                } else if (lesson.lesson === "writing") {
                                    iconCls = "fa fa-pencil";
                                }

                                if (lesson._id === "5ca62d50cb3a224f4cc11138") {
                                    // console.log();
                                }
                                const hasReadingIntro = this.hasReadingIntro(
                                    lesson
                                );

                                let mLink = `/${pageName}?materialID=${
                                    lesson._id
                                }&classID=${classID}`;

                                if (hasReadingIntro)
                                    mLink = `/reading/intro?materialID=${
                                        lesson._id
                                    }&classID=${classID}`;

                                return (
                                    <div className="col-md-6" key={index}>
                                        <Link
                                            to={mLink}
                                            style={{
                                                textTransform: "capitalize"
                                            }}
                                        >
                                            <i
                                                className={iconCls}
                                                aria-hidden="true"
                                            />{" "}
                                            {lesson.lesson}
                                        </Link>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default UnitBox;
