import React, { Component } from "react";
import queryString from "query-string";

import TeachingMaterials from "./TeachingMaterials";

import schoolService from "../../services/schoolService";

import { AppContext } from "../../App";
import PreKMaterialList from "./preKMaterialList";

class MaterialLanding extends Component {
    state = {
        classInfo: null
    };
    async componentDidMount() {
        const { app, match, location } = this.props;
        const query = queryString.parse(location.search);
        const classID = query.classID;
        if (!classID) return;
        try {
            const { data: classInfo } = await schoolService.getPublicClassInfo(
                classID
            );
            this.setState({
                classInfo
            });
            // console.log(classInfo);
        } catch (ex) {
            console.log(ex);
        }
        app.setState({
            pageTitle: "Classroom"
        });
    }
    render() {
        const { classInfo } = this.state;

        if (!classInfo) return "";

        if (classInfo.grade && classInfo.grade.name === "preK") {
            return <PreKMaterialList />;
        }

        return <TeachingMaterials classID={classInfo._id} />;
    }
}

const MaterialLanding_ = props => (
    <AppContext.Consumer>
        {app => <MaterialLanding {...props} app={app} />}
    </AppContext.Consumer>
);

export default MaterialLanding_;
