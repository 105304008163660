import httpService from "./httpService";

const endpoint = "/alphabets";

const alphabetService = class {
    constructor() {}

    getAlphabets = async () => {
        return await httpService.get(endpoint);
    };
    getAlphabet = async id => {
        return await httpService.get(endpoint + "/" + id);
    };
    getAlphabetByletter = async letter => {
        return await httpService.get(endpoint + "/letter/" + letter);
    };
    createAlphabet = async data => {
        return await httpService.post(endpoint, data);
    };
    updateAlphabet = async data => {
        return await httpService.put(endpoint, data);
    };
};

export default new alphabetService();
