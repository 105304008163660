import React, { Component } from "react";

const ChallengeOpt = ({
    question,
    index,
    option,
    answers,
    optDesgin,
    showAnserFlag,
    blinkAnswer,
    ...pros
}) => {
    const optLabels = ["A", "B", "C", "D", "E", "F", "G"];
    const isPicked = () => {
        return answers.includes(option);
    };
    const colors = [
        "challengeOpt_red",
        "challengeOpt_lightbBlue",
        "challengeOpt_green",
        "challengeOpt_darkBlue"
    ];
    const getColor = () => {
        let num = index;
        if (num > colors.length - 1) {
            num = colors.length % index;
        }

        return colors[num];
    };
    const getCls = () => {
        let cls = "optWrap";
        if (isPicked()) {
            cls = cls + " active";
        }

        if (showAnserFlag && blinkAnswer && isCorrectAnswer())
            cls = cls + " answer";

        return cls;
    };
    const isCorrectAnswer = () => {
        return question.answers.includes(option);
    };
    return (
        <div
            style={{
                cursor: "pointer"
            }}
            onClick={pros.onClick}
            className={getCls()}
        >
            <div className={"challengeOpt " + optDesgin + " " + getColor()}>
                {optDesgin === "list" && (
                    <span>
                        {optLabels[index]}.{option}
                    </span>
                )}
                {optDesgin === "box" && (
                    <span>
                        {option}{" "}
                        <span className="label">{optLabels[index]}</span>
                    </span>
                )}
            </div>
        </div>
    );
};

export default ChallengeOpt;
