import $ from "jquery";
import React, { Component } from "react";

const ClassLanding_nameCard = ({ student }) => {
    if (!student) return "";
    const icons = [
        "head_Farmer",
        "head_grandma_dirty",
        "head_Henry",
        "head_Laura",
        "head_Queen",
        "head_TedFred",
        "head_wwwDog"
    ];
    const nameCardCsses = [
        "blueNameCard",
        "orangeNameCard",
        "purpleNameCard",
        "greenNameCard",
        "yellowNameCard",
        "redNameCard"
    ];
    const abc = "abcdefghijklmnopqrstuvwxyz";
    const getIcon = name => {
        const str = name[name.length - 1].toLowerCase();

        let num = parseInt(str);
        if (isNaN(num)) {
            num = abc.indexOf(str);
        }
        if (num > icons.length - 1) {
            num = num % icons.length;
        }

        return "/portal-images/" + icons[num] + ".png";
    };
    const getNameCardCss = name => {
        const str = name[name.length - 1].toLowerCase();

        let num = parseInt(str);
        if (isNaN(num)) {
            num = abc.indexOf(str);
        }
        if (num > nameCardCsses.length - 1) {
            num = num % nameCardCsses.length;
        }

        return nameCardCsses[num];
    };

    return (
        <div className="nameCardWrap">
            <div className={getNameCardCss(student.name) + " nameCard"}>
                <table>
                    <tbody>
                        <tr>
                            <td className="icon">
                                <div>
                                    <img src={getIcon(student.name)} />
                                </div>
                            </td>
                            <td className="name">
                                <span>{student.name}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ClassLanding_nameCard;
