import React, { Component } from "react";

class Modal extends Component {
    state = {};
    render() {
        const {
            closeBtn,
            confirmBtn,
            size,
            title,
            handleClose,
            handleConfirm,
            id
        } = this.props;

        return (
            <div
                className="modal fade"
                id={id}
                tabIndex="-1"
                role="dialog"
                // aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className={
                        (size || "") + " modal-dialog modal-dialog-centered "
                    }
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                // id="exampleModalCenterTitle"
                            >
                                {title}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">{this.props.children}</div>

                        {!closeBtn && !confirmBtn ? (
                            ""
                        ) : (
                            <div className="modal-footer">
                                {closeBtn && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        {closeBtn}
                                    </button>
                                )}
                                {confirmBtn && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleConfirm}
                                    >
                                        {confirmBtn}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
