import "../../css/book.css";
import $ from "jquery";
import queryString from "query-string";
import React, { Component } from "react";
import HtmlToReactParser from "html-to-react";
const html = HtmlToReactParser.Parser();

class BookDesign extends Component {
    state = {};
    render() {
        return (
            <div
                className="row"
                style={{
                    height: "100%"
                }}
            >
                <div
                    id="bookCol"
                    className="col"
                    style={{
                        display: "flex",
                        alignSelf: "center",
                        overflow: "hidden"
                    }}
                >
                    {this.renderAudioPlayer()}
                    <div id="book">
                        <div id="bookBody">{this.props.children}</div>
                        {this.renderArrowComp()}
                    </div>
                </div>
            </div>
        );
    }
    renderAudioPlayer = () => {
        const { audioComp } = this.props;

        if (audioComp) return audioComp;

        return "";
    };

    renderArrowComp = () => {
        const { arrowComp } = this.props;
        if (arrowComp) return arrowComp;

        return "";
    };
}

export default BookDesign;
