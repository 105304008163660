import ReactResizeDetector from "react-resize-detector";
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Book from "./book";

import Home from "./pages/home";
import DashBoard from "./pages/dashboard";
import AlphabetComp from "./materialComps/alphabet";
import TeachingMaterials from "./materialComps/TeachingMaterials";
import AssignmentComp from "./assignmentComps/assignmentComp";
import Challenges from "./pages/challenges";
import ChallengeComp from "./challengeComps/challengeComp";

import CompletedChallenge from "./pages/completedChallenge";
import NotFound from "./pages/NotFound";
import SchoolLanding from "./pages/schoolLanding";
import ClassLanding from "./classComps/classLanding";

import MaterialLanding from "./materialComps/materialLanding";
import LessonDesign_1 from "./materialComps/lessonDesign_1";
import BookDesign_reading from "./materialComps/bookDesign_reading";

import ProtectedRoute from "./navComps/protectedRoute";
import TeacherRoute from "./navComps/teacherRoute";

import utils from "../utils/utils";

class MainContent extends Component {
    constructor(props) {
        super(props);
        const comp = this;
        window.addEventListener("orientationchange", function() {
            // setTimeout(() => {
            //     console.log("orientationchange");
            //     comp.setMainContentStyles();
            // }, 50);
            // $("#boardCol").height(window.screen.height - 64);
        });

        this.state = {
            bgHeight: this.getBgHeight()
        };
    }
    componentDidMount() {
        // console.log(this.props);
    }

    onResize = (width, height) => {
        console.log("resize");
        // console.log(width, height, window.innerHeight);
        // this.setState({
        //     appWidth: width,
        //     appHeight: height
        // });
        this.setMainContentStyles();
    };
    getBgHeight = () => {
        const ori = utils.getOrientation();
        let h = 600;
        if (ori === "l" && window.innerHeight > 600) {
            h = window.innerHeight + "px";
        } else if (ori === "p") {
            h = window.innerHeight;
        }

        return h;
    };
    setMainContentStyles = () => {
        this.setState({
            bgHeight: this.getBgHeight()
        });
    };
    render() {
        const { bgHeight } = this.state;
        return (
            <div
                className="row"
                id="mainContent"
                style={{ minHeight: bgHeight }}
            >
                <div className="bg" style={{ height: bgHeight }} />
                <ReactResizeDetector
                    handleWidth
                    handleHeight
                    onResize={this.onResize}
                />
                <div className="col" style={{ paddingTop: "30px" }}>
                    <Switch>
                        <Route path="/home" component={Home} />
                        <TeacherRoute path="/DashBoard" component={DashBoard} />
                        {/* <TeacherRoute
                            path="/teachingMaterials"
                            component={TeachingMaterials}
                        /> */}

                        <TeacherRoute
                            path="/teachingMaterials"
                            component={MaterialLanding}
                        />

                        <TeacherRoute
                            path="/challenges"
                            component={Challenges}
                        />
                        <TeacherRoute
                            path="/assignments"
                            component={AssignmentComp}
                        />
                        <Route path="/challenge" component={ChallengeComp} />
                        <Route
                            path="/completedChallenge"
                            component={CompletedChallenge}
                        />
                        <TeacherRoute
                            path="/alphabet"
                            component={AlphabetComp}
                        />
                        <TeacherRoute
                            path="/lesson"
                            component={LessonDesign_1}
                        />
                        <TeacherRoute
                            path="/reading/intro"
                            component={LessonDesign_1}
                        />
                        {/* <TeacherRoute path="/reading" component={Book} /> */}
                        <TeacherRoute
                            path="/reading"
                            component={BookDesign_reading}
                        />
                        <Route
                            path="/school/:schoolCode"
                            component={SchoolLanding}
                        />
                        <Route
                            path="/class/:classID"
                            component={ClassLanding}
                        />
                        <Route path="/" exact component={Home} />
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default MainContent;
