import React, { Component } from "react";
import QuestionCarousel_card from "./challengeCarousel_card";
import $ from "jquery";
import moment from "moment";

class ChallengeCarousel extends Component {
    constructor(props) {
        super(props);
        const { challenge, challengeID, assignmentID, curUser } = props;
        this.state = {
            slideRefs: [],
            curSlideIndex: 0,
            pickedStudents: []
        };
        challenge.questions.forEach(q => {
            this.state.slideRefs.push(React.createRef());
        });
    }
    componentDidMount() {
        $("#challengeCarousel").carousel({
            ride: false,
            interval: false,
            keyboard: false,
            wrap: false,
            touch: false
        });
        $("#challengeCarousel").on("slide.bs.carousel", e => {
            this.slideChange(e);
        });
    }
    ifShowTeacherTools = () => {
        const { challenge, challengeID, assignmentID, curUser } = this.props;
        if (
            curUser &&
            (curUser.isSuper || curUser.isManager || curUser.isTeacher)
        )
            return true;

        return false;
    };
    slideChange = e => {
        console.log("slide change");
        const now = moment();
        const { slideRefs } = this.state;
        const fromSlide = slideRefs[e.from].current;
        const toSlide = slideRefs[e.to].current;
        fromSlide.state.showAnserFlag = false;

        this.setState({
            curSlideIndex: e.to
        });

        this.props.progressBar.current.updateProgress(e.to);
        fromSlide.state.timeSpend =
            fromSlide.state.timeSpend +
            now.diff(fromSlide.state.timeBegin, "seconds");
        fromSlide.state.timeBegin = null;

        toSlide.state.timeBegin = now.clone();
    };
    ifChallengeCompleted = () => {
        let flag = true;
        const { slideRefs } = this.state;
        for (let i = 0; i < slideRefs.length; i++) {
            const ref = slideRefs[i];
            if (!ref.current) {
                flag = false;
                break;
            }
            const count = ref.current.state.answers.length;
            if (count === 0) {
                flag = false;
                break;
            }
        }
        // console.log("ifChallengeCompleted", flag);
        return flag;
    };
    collectResult = () => {
        const now = moment();
        const { slideRefs } = this.state;

        const lastSlideRef = slideRefs[slideRefs.length - 1].current;

        lastSlideRef.state.timeSpend =
            lastSlideRef.state.timeSpend +
            now.diff(lastSlideRef.state.timeBegin, "seconds");

        const result = slideRefs.map(ref => {
            const slide = ref.current;
            const answer = {
                question: slide.props.question._id,
                answers: slide.state.answers,
                timeSpend: slide.state.timeSpend
            };
            return answer;
        });

        return result;
    };
    clearPickedStudents = () => {
        console.log("clear");
        this.setState({
            pickedStudents: []
        });
    };
    addPickedStudents = student => {
        const pickedStudents = [...this.state.pickedStudents];
        pickedStudents.push(student);
        this.setState({
            pickedStudents
        });
    };
    ifStudentedPicked = student => {
        return this.state.pickedStudents.includes(student);
    };
    render() {
        const {
            challenge,
            challengeID,
            assignmentID,
            curUser,
            classInfo,
            completeChallenge
        } = this.props;
        const { slideRefs, curSlideIndex } = this.state;
        if (!challenge) return "";
        return (
            <React.Fragment>
                <div id="challengeCarousel" className="slide carousel">
                    <div id="challengeQuestionNum">
                        {curSlideIndex + 1} of {challenge.questions.length}
                    </div>
                    <div className="carousel-inner">
                        {challenge.questions.map((q, index) => {
                            return (
                                <QuestionCarousel_card
                                    key={index}
                                    question={q}
                                    index={index}
                                    challenge={challenge}
                                    curUser={curUser}
                                    classInfo={classInfo}
                                    ref={slideRefs[index]}
                                    completeChallenge={completeChallenge}
                                    ifChallengeCompleted={
                                        this.ifChallengeCompleted
                                    }
                                    carouselComp={this}
                                />
                            );
                        })}
                    </div>
                    <a
                        className="navBack"
                        href="#challengeCarousel"
                        role="button"
                        data-slide="prev"
                    >
                        <img src="/portal-images/leftArrow_sm.png" />
                    </a>
                    {this.ifShowTeacherTools() && (
                        <a
                            className="navNext"
                            href="#challengeCarousel"
                            role="button"
                            data-slide="next"
                        >
                            <img src="/portal-images/rightArrow_sm.png" />
                        </a>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default ChallengeCarousel;
