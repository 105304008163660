import $ from "jquery";
const Utils = class {
    getOrientation() {
        if (window.innerWidth < 992) return "p";
        if (window.innerHeight > window.innerWidth) {
            return "p";
        }
        return "l";
    }
};

export default new Utils();
