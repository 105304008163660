import http from "./httpService";

const apiEndpoint = "/classActions";

const utils = {
    createCodes: async (data, classID) => {
        http.setClassIDHeader(classID);

        return await http.post(apiEndpoint + "/createCodes", data);
    },
    deleteCode: async (code, classID) => {
        http.setClassIDHeader(classID);

        return await http.delete(apiEndpoint + "/deleteCode/" + code);
    }
};

export default utils;
