import axios from "axios";
import { toast } from "react-toastify";

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timemout: 2000
});

http.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    // console.log(error);
    if (error && error.message === "Network Error") {
        console.log(error);
        toast.error("Network error! Check your network connection!");
    } else if (error && error.response && error.response.status === 404) {
        console.log("error.response");
        toast.error("Api End Point Not Found!");
    } else if (expectedError) {
        console.log(error.response);
        toast.error(error.response.data);
    } else if (error.response) {
        console.log("http error", error);
        toast.error("An unexpected error occurred.");
    }

    return Promise.reject(error);
});

function setJwt(jwt) {
    // console.log("jwt", jwt);
    http.defaults.headers.common["x-auth-token"] = jwt;
}

function removeXHeaders() {
    delete http.defaults.headers.common["x-schoolID"];
    delete http.defaults.headers.common["x-classID"];
    delete http.defaults.headers.common["x-userID"];
}

function setSchoolIDHeader(schoolID) {
    http.defaults.headers.common["x-schoolID"] = schoolID;
}

function setClassIDHeader(classID) {
    http.defaults.headers.common["x-classID"] = classID;
}

function setUserIDHeader(userID) {
    http.defaults.headers.common["x-userID"] = userID;
}

export default {
    get: http.get,
    post: http.post,
    put: http.put,
    patch: http.patch,
    delete: http.delete,
    setJwt,
    setSchoolIDHeader,
    setClassIDHeader,
    setUserIDHeader,
    removeXHeaders
};

// export class Http {
//     constructor() {
//         console.log("http constructor");
//         this.http = axios.create({
//             baseURL: process.env.REACT_APP_API_URL,
//             timemout: 2000
//         });
//         this.http.interceptors.response.use(null, error => {
//             const expectedError =
//                 error.response &&
//                 error.response.status >= 400 &&
//                 error.response.status < 500;

//             // console.log(error);
//             if (error && error.message === "Network Error") {
//                 console.log(error);
//                 toast.error("Network error! Check your network connection!");
//             } else if (
//                 error &&
//                 error.response &&
//                 error.response.status === 404
//             ) {
//                 console.log("error.response");
//                 toast.error("Api End Point Not Found!");
//             } else if (expectedError) {
//                 console.log(error.response);
//                 toast.error(error.response.data);
//             } else if (error.response) {
//                 console.log("http error", error);
//                 toast.error("An unexpected error occurred.");
//             }

//             return Promise.reject(error);
//         });
//     }

//     // get = this.http.get;
//     // post = this.http.post;
//     // put = this.http.put;
//     // patch = this.http.patch;
//     // delete = this.http.delete;
//     setJwt = setJwt;
//     setSchoolIDHeader = setSchoolIDHeader;
//     setClassIDHeader = setClassIDHeader;
//     setUserIDHeader = setUserIDHeader;
// }
