import React, { Component } from "react";
import { Link } from "react-router-dom";

class GradeBox extends Component {
    state = {};
    render() {
        const { grade: g } = this.props;
        return (
            <div className="gradeBox">
                <div className="header">{`Grade ${g.name}`}</div>
                <div className="body row">
                    {g.gClasses.map(c => {
                        return this.renderClass(c);
                    })}
                </div>
            </div>
        );
    }

    renderClass = gClass => {
        return (
            <div className="col-md-6 " key={gClass._id}>
                <Link
                    style={{ cursor: "pointer", textTransform: "uppercase" }}
                    to={"/class/" + gClass._id}
                    className="classLink"
                >
                    Class {gClass.name}
                </Link>
            </div>
        );
    };
}

export default GradeBox;
