import React, { Component } from "react";
import alphabetService from "../../services/alphabetService";

class PreKMaterialList extends Component {
    state = {
        alphabets: []
    };
    async componentDidMount() {
        try {
            const { data: alphabets } = await alphabetService.getAlphabets();
            this.setState({
                alphabets
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const { alphabets } = this.state;
        if (alphabets.length == 0) return "";
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3 className="pageHead">
                            <i className="fa fa-list" aria-hidden="true" />{" "}
                            Teaching Materials
                        </h3>
                    </div>
                </div>
                <div className="row">
                    {alphabets.map((alphabet, index) => {
                        const url = "/alphabet?letter=" + alphabet.letter;
                        return (
                            <div
                                className="col-md-2"
                                key={index}
                                style={{ marginBottom: "20px" }}
                            >
                                <div className="card">
                                    <div className="card-body">
                                        <div
                                            className="card-title"
                                            style={{ textAlign: "center" }}
                                        >
                                            <a
                                                style={{
                                                    fontSize: "24pt"
                                                }}
                                                href={url}
                                            >
                                                {alphabet.letter.toUpperCase()}{" "}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default PreKMaterialList;
