import React from "react";
import { Link, NavLink } from "react-router-dom";
import authService from "../../services/authService";
const Nav_dropdown = ({ curUser, query }) => {
    return (
        <div>
            <ul className="nav pull-right">
                <li className="nav-item dropdown" id="siteNavDropDown">
                    <a
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="navUser"
                        style={{ textTransform: "uppercase" }}
                    >
                        {curUser.name}
                    </a>
                    <div className="dropdown-menu" id="navSub">
                        <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                authService.logout();
                                window.location.href = "/";
                            }}
                        >
                            <span className="fa fa-sign-out" /> Log Out
                        </span>
                    </div>
                </li>
                <li className="nav-item d-none" id="classRoomBackBtn">
                    <Link
                        to={"/teachingMaterials?classID=" + query.classID}
                        className="nav-link"
                    >
                        <i
                            className="fa fa-caret-square-o-left"
                            aria-hidden="true"
                        />{" "}
                        Back to Materials
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Nav_dropdown;
