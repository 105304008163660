import React, { Component } from "react";
import imgUtils from "../../utils/img";
import { Link, NavLink } from "react-router-dom";
import { AppContext } from "../../App";
import queryString from "query-string";
import Nav_dropdown from "./nav_dropdown";

class Navigation extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        navVisiblity: "hidden"
    };
    componentDidMount() {
        // console.log(this.props);
        // console.log("nav mount");
        this.setNavVisibility();
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log("did update");
        this.setNavVisibility();
    }
    setNavVisibility = () => {
        const { location, match } = this.props;
        // console.log(match);
        const query = queryString.parse(location.search);
        let navVisiblity = "hidden";
        if (query.classID || (match && match.params.classID)) {
            navVisiblity = "visible";
        }

        if (navVisiblity != this.state.navVisiblity) {
            this.setState({ navVisiblity });
        }
    };
    isActiveNav = (match, location, navName) => {
        // console.log(match, location, navName);
        if (location.pathname && location.pathname.includes(navName)) {
            return "active";
        }
    };
    render() {
        const { location, match } = this.props;
        const { pageTitle, curUser } = this.props.app.state;
        const query = queryString.parse(location.search);
        const { navVisiblity } = this.state;

        return (
            <nav
                id="navBar"
                className="navbar navbar-expand-lg navbar-light bg-light sticky-top"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                    id="navLogo"
                >
                    <Link to="/" className="navbar-brand">
                        <img
                            src={
                                process.env.REACT_APP_RES_URL +
                                "portal-images/Dr_ABC_logo_sm.png"
                            }
                            className="logo"
                            style={{ height: 46 }}
                        />
                        <span className="highlight">Dr.</span>
                        <span className="highlight a">A</span>
                        <span className="highlight b">B</span>
                        <span className="highlight c">C</span>
                        <span className="text2 d-none d-lg-inline">
                            {" "}
                            Classroom
                        </span>
                    </Link>
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>

                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul
                        className="navbar-nav mr-auto"
                        style={{ visibility: navVisiblity }}
                    >
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard">
                                Dashboard
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={
                                    "nav-link " +
                                    this.isActiveNav(
                                        match,
                                        location,
                                        "teachingMaterials"
                                    )
                                }
                                to={
                                    "/teachingMaterials?classID=" +
                                    query.classID
                                }
                            >
                                Teaching Materials
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={
                                    "nav-link " +
                                    this.isActiveNav(
                                        match,
                                        location,
                                        "challenges"
                                    )
                                }
                                to={"/challenges?classID=" + query.classID}
                            >
                                Challenges
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={
                                    "nav-link " +
                                    this.isActiveNav(
                                        match,
                                        location,
                                        "assignments"
                                    )
                                }
                                to={"/assignments?classID=" + query.classID}
                            >
                                Assignments
                            </Link>
                        </li>
                    </ul>
                    {curUser && (
                        <Nav_dropdown curUser={curUser} query={query} />
                    )}
                </div>
            </nav>
        );
    }
}

const _Navigation = props => {
    return (
        <AppContext.Consumer>
            {app => <Navigation {...props} app={app} />}
        </AppContext.Consumer>
    );
};

export default _Navigation;
