import React, { Component } from "react";

class ChallengeProgressBar extends Component {
    state = {
        curSlideIndex: 0
    };

    updateProgress = index => {
        this.setState({
            curSlideIndex: index
        });
    };

    render() {
        const { challenge } = this.props;
        const { curSlideIndex } = this.state;

        const progress =
            (parseFloat(curSlideIndex + 1) / challenge.questions.length) * 100 +
            "%";

        // console.log(progress);
        return (
            <div id="challengeProgressBar">
                <div className="progress" style={{ width: progress }} />
                <div className="car" style={{ left: progress }} />
                <div className="progressKnob" style={{ left: progress }} />
                <div className="progressDot" style={{ left: progress }} />
                <div className="endDot" />
                <div className="endKnob" />
            </div>
        );
    }
}

export default ChallengeProgressBar;
