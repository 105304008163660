import http from "./httpService";
import auth from "./authService";
const apiEndpoint = "/users";

http.setJwt(auth.getJwt());

async function register(user) {
    return await http.post(apiEndpoint, user);
}

async function updateUser(user) {
    return await http.put(apiEndpoint + "/" + user._id, user);
}

async function getUsers() {
    return await http.get(apiEndpoint);
}

async function getUser(userID) {
    return await http.get(apiEndpoint + "/" + userID);
}

async function deleteUser(userID) {
    return await http.delete(apiEndpoint + "/" + userID);
}

export default {
    register,
    updateUser,
    getUsers,
    getUser,
    deleteUser
};
