import $ from "jquery";
import React, { Component } from "react";
import queryString from "query-string";

import ChallengeCarousel from "./challengeCarousel";
import ChallengeProgressBar from "./challengeProgressBar";
import ChallengeTimer from "./challengeTimer";

import challengeService from "../../services/challengeService";
import schoolService from "../../services/schoolService";
import challengeResultservice from "../../services/challengeResultservice";

import { AppContext } from "../../App";
import { toast } from "react-toastify";
import ChallengeCarousel_teacherTools from "./challengeCarousel_teacherTools";

class ChallengeComp extends Component {
    state = {
        challenge: null,
        challengeID: null,
        assignmentID: null,
        classInfo: null,
        classID: null,
        studentID: null,
        challengeCarousel: React.createRef(),
        progressBar: React.createRef(),
        timer: React.createRef()
    };
    async componentDidMount() {
        $("#mainContent").addClass("class");
        const { location } = this.props;
        const query = queryString.parse(location.search);
        if (!query.challengeID) return;

        try {
            const { data: challenge } = await challengeService.getChallenge(
                query.challengeID
            );
            const data = {
                challengeID: query.challengeID,
                assignmentID: query.assignmentID,
                studentID: query.studentID,
                challenge
            };

            if (query.classID) {
                const { data: classInfo } = await schoolService.getClass(
                    query.classID
                );
                // console.log(classInfo);
                data.classInfo = classInfo;
                data.classID = query.classID;
            }

            this.setState(data);
        } catch (ex) {
            console.log(ex);
        }
    }
    componentWillUnmount() {
        $("#mainContent").removeClass("class");
    }
    completeChallenge = async submitFlag => {
        if (submitFlag) {
            await this.submitChallenge();
        }
        const { location, app, history } = this.props;

        history.replace("/completedChallenge");
    };
    submitChallenge = async () => {
        const {
            challenge,
            challengeID,
            assignmentID,
            studentID,
            classInfo,
            challengeCarousel,
            progressBar,
            timer
        } = this.state;

        if (!studentID) return;

        const result = {
            userID: studentID,
            challenge: challengeID,
            assignment: assignmentID,
            app: "classroom",
            completed: true,
            timeSpend: timer.current.state.time,
            detail: challengeCarousel.current.collectResult()
        };
        try {
            const { data } = await challengeResultservice.createResult(result);
            toast.success("Your answer has been submited successfully!");
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        const { app } = this.props;
        const {
            challenge,
            challengeID,
            assignmentID,
            classInfo,
            challengeCarousel,
            progressBar,
            timer
        } = this.state;
        if (!challenge) return "";

        return (
            <div className="container">
                <ChallengeTimer ref={timer} />
                <div
                    style={{
                        background: "#fff",
                        padding: "30px",
                        borderRadius: "15px"
                    }}
                >
                    <ChallengeCarousel
                        challenge={challenge}
                        challengeID={challengeID}
                        assignmentID={assignmentID}
                        curUser={app.state.curUser}
                        classInfo={classInfo}
                        completeChallenge={this.completeChallenge}
                        ref={challengeCarousel}
                        progressBar={progressBar}
                    />
                </div>
                <ChallengeProgressBar challenge={challenge} ref={progressBar} />
            </div>
        );
    }
}

const ChallengeComp_ = props => {
    return (
        <AppContext.Consumer>
            {app => {
                return <ChallengeComp app={app} {...props} />;
            }}
        </AppContext.Consumer>
    );
};

export default ChallengeComp_;
