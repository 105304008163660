import $ from "jquery";
import ReactResizeDetector from "react-resize-detector";
import React, { Component } from "react";
import schoolService from "../../services/schoolService";
import ClassLanding_nameCard from "./classLanding_nameCard";
import assignmentService from "../../services/assignmentService";

class ClassLanding extends Component {
    state = {
        info: null,
        selectedStudent: null,
        studentConfirmed: false,
        assignment: null
    };
    async componentDidMount() {
        $("#mainContent").addClass("class");
        const { match } = this.props;
        const classID = match.params.classID;
        if (!classID) return;
        try {
            const { data: info } = await schoolService.getPublicClassInfo(
                classID
            );

            const {
                data: assignment
            } = await assignmentService.getLatenessTest(classID);
            this.setState({ info, assignment });
        } catch (error) {}
    }
    componentWillUnmount() {
        $("#mainContent").removeClass("class");
    }
    selectStudent = student => {
        this.setState({ selectedStudent: student, studentConfirmed: true });
    };
    disSelectStudnet = () => {
        this.setState({
            selectedStudent: null,
            studentConfirmed: false
        });
    };
    render() {
        const {
            info,
            selectedStudent,
            studentConfirmed,
            assignment
        } = this.state;
        const { history } = this.props;
        if (!info) {
            console.log("info", info);
            console.log("comp not rendered");
            return "";
        }
        return (
            <div className="container">
                <div className="row" id="classLogin">
                    <ReactResizeDetector
                        handleWidth
                        handleHeight
                        onResize={(w, h) => {
                            setTimeout(() => {
                                const hight = $(
                                    "#classLogin .right img"
                                ).height();
                                $("#classLogin>div").height(hight);
                            }, 200);
                        }}
                    />
                    <div className="col-3 text-right">
                        <table style={{ height: "100%", width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                        <img
                                            src="/portal-images/dog_sm.png"
                                            style={{ maxWidth: "100%" }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table style={{ height: "100%", width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ verticalAlign: "middle" }}>
                                        <div className="msg">
                                            {!studentConfirmed && (
                                                <div>
                                                    <div className="line1">
                                                        Welcome! Student!
                                                    </div>
                                                    <div className="line2">
                                                        Login to your name:
                                                    </div>
                                                </div>
                                            )}
                                            {studentConfirmed && assignment && (
                                                <div>
                                                    <div
                                                        className="line1"
                                                        style={{
                                                            fontSize: "18pt"
                                                        }}
                                                    >
                                                        Are You{" "}
                                                        <span
                                                            style={{
                                                                textTransform:
                                                                    "uppercase"
                                                            }}
                                                        >
                                                            {
                                                                selectedStudent.name
                                                            }
                                                        </span>
                                                        ?
                                                    </div>
                                                </div>
                                            )}

                                            {studentConfirmed && !assignment && (
                                                <div>
                                                    <div
                                                        className="line1"
                                                        style={{
                                                            fontSize: "18pt"
                                                        }}
                                                    >
                                                        Oh, there is no
                                                        challenge assigned to
                                                        You!
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-3 right">
                        <img
                            src="/portal-images/drsm.png"
                            style={{ maxWidth: "100%" }}
                        />
                    </div>
                </div>
                {!studentConfirmed && (
                    <div>
                        <div className="row">
                            {info.students.map((st, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            "col-xl-3 col-lg-4 col-sm-6 text-center " +
                                            "student" +
                                            index
                                        }
                                        onClick={() => {
                                            $(".nameCardWrap").removeClass(
                                                "active"
                                            );
                                            $(".student" + index)
                                                .find(".nameCardWrap")
                                                .addClass("active");
                                            this.selectStudent(st);
                                        }}
                                    >
                                        <ClassLanding_nameCard student={st} />
                                    </div>
                                );
                            })}
                        </div>

                        {/* <div className="row" style={{ marginTop: "30px" }}>
                            <div className="col text-center">
                                <button
                                    disabled={!selectedStudent}
                                    id="studentLoginBtn"
                                    onClick={() => {
                                        this.setState({
                                            studentConfirmed: true
                                        });
                                    }}
                                >
                                    Log In
                                </button>
                            </div>
                        </div> */}
                    </div>
                )}

                {studentConfirmed && assignment && (
                    <div>
                        <div style={{ width: 400, margin: "auto" }}>
                            <ClassLanding_nameCard student={selectedStudent} />
                        </div>

                        <div style={{ width: 568, margin: "auto" }}>
                            <button
                                id="classBeginTestBtn"
                                onClick={() => {
                                    history.push(
                                        `/challenge?challengeID=${
                                            assignment.challenge
                                        }&studentID=${
                                            selectedStudent.student
                                        }&assignmentID=${assignment._id}`
                                    );
                                }}
                            >
                                Yes! Begin Test!
                            </button>
                            <button
                                id="classSwitchUserBtn"
                                onClick={() => {
                                    this.disSelectStudnet();
                                }}
                            >
                                No! Switch User!
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ClassLanding;
