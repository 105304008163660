import http from "./httpService";
import auth from "./authService";
const questionEndpoint = "/questions";
const challengeEndpoint = "/challenges";

http.setJwt(auth.getJwt());

const Utils = class {
    getQuestions = async data => {
        for (let p in data) {
            if (data[p] === "" || data[p] === null) delete data[p];
        }

        return await http.get(
            questionEndpoint + "?filter=" + JSON.stringify(data)
        );
    };

    localQuestionFilterName = "questionPageFilter";
    getQuestionFilter = () => {
        const localFilterJson = localStorage.getItem(this.localFilterName);
        if (localFilterJson) {
            const obj = JSON.parse(localFilterJson);
            return obj;
        }
        return {
            owner: "system",
            grade: "1",
            unit: 1,
            lesson: ""
        };
    };
    saveQuestionFilter = data => {
        localStorage.setItem(this.localFilterName, JSON.stringify(data));
    };

    createQuestion = async data => {
        return await http.post(questionEndpoint, data);
    };

    updateQuestion = async data => {
        return await http.put(questionEndpoint + "/" + data._id, data);
    };

    deleteQuestion = async id => {
        return await http.delete(questionEndpoint + "/" + id);
    };

    //challenges

    getChallenges = async data => {
        for (let p in data) {
            if (data[p] === "" || data[p] === null) delete data[p];
        }

        return await http.get(
            challengeEndpoint + "?filter=" + JSON.stringify(data)
        );
    };

    getChallenge = async id => {
        return await http.get(challengeEndpoint + "/" + id);
    };

    createChallenge = async data => {
        return await http.post(challengeEndpoint, data);
    };

    updateChallenge = async data => {
        return await http.put(challengeEndpoint + "/" + data._id, data);
    };
    deleteChallenge = async id => {
        return await http.delete(challengeEndpoint + "/" + id);
    };

    getChallengesByClassID = async classID => {
        return await http.get(challengeEndpoint + "/getByClassID/" + classID);
    };
};

export default new Utils();
