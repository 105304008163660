import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Input, Button, Icon } from "antd";
import queryString from "query-string";
import moment from "moment";

import schoolService from "../../services/schoolService";
import challengeResultservice from "../../services/challengeResultservice";

class ClassAssignmentResult extends Component {
    state = {
        classInfo: null,
        challengeResults: []
    };
    async componentDidMount() {
        const { match, location } = this.props;
        const query = queryString.parse(location.search);
        const classID = query.classID;
        const assignmentID = match.params.assignmentID;
        if (!classID) return;
        if (!assignmentID) return;

        try {
            const { data: classInfo } = await schoolService.getClass(classID);

            const {
                data: challengeResults
            } = await challengeResultservice.getAssignmentResults(assignmentID);

            this.setState({
                classInfo,
                challengeResults
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    componentWillUnmount() {}

    render() {
        return <div>{this.renderStudentTbl()}</div>;
    }
    getStudentResult = student => {
        const studentID = student.student._id || student.student;
        return this.state.challengeResults.find(r => r.userID === studentID);
    };
    renderStudentTbl = () => {
        const { classInfo } = this.state;

        if (!classInfo) return "";

        let students = classInfo.ActivationCodes;

        const columns = [
            {
                title: "Name",
                dataIndex: "student.name",
                render: (v, r, i) => {
                    return (
                        <span style={{ textTransform: "uppercase" }}>
                            {v || r.name}
                        </span>
                    );
                }
            },
            {
                title: "Score / 100",
                render: (v, r) => {
                    const result = this.getStudentResult(r);
                    if (result) return <span>{result.score}</span>;
                }
            },
            {
                title: "Time Spent",
                render: (v, r) => {
                    const result = this.getStudentResult(r);
                    if (result) {
                        const start = new moment();
                        const end = start
                            .clone()
                            .add(result.timeSpend, "seconds");
                        const dif = end.diff(start);
                        const timer = moment.utc(dif).format("HH:mm:ss");
                        return <span>{timer}</span>;
                    }
                }
            }
            // {
            //     title: "Completed",
            //     render: (v, r) => {
            //         const result = this.getStudentResult(r);
            //         if (result)
            //             return (
            //                 <button className="btn btn-primary">Result</button>
            //             );
            //     }
            // }
        ];

        return (
            <Table
                columns={columns}
                dataSource={students}
                pagination={false}
                bordered={true}
                rowKey="_id"
            />
        );
    };
}

export default ClassAssignmentResult;
