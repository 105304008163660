import React, { Component } from "react";
import moment from "moment";

class ChallengeTimer extends Component {
    state = {
        timer: "00:00:00",
        time: 0,
        timeInterval: null
    };
    componentDidMount() {
        const start = new moment();

        const timeInterval = setInterval(() => {
            const now = new moment();
            const time = now.diff(start, "seconds");
            const dif = now.diff(start);
            const timer = moment.utc(dif).format("HH:mm:ss");

            this.setState({
                timer,
                time
            });
        }, 500);
        this.setState({
            timeInterval
        });
    }
    componentWillUnmount() {
        window.clearInterval(this.state.timeInterval);
    }
    render() {
        return (
            <div id="challengeTimer">
                <div className="body">
                    <div className="timer">{this.state.timer}</div>
                </div>
            </div>
        );
    }
}

export default ChallengeTimer;
