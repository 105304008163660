import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Input, Button, Icon } from "antd";
import queryString from "query-string";

import challengeService from "../../services/challengeService";

class Challenges extends Component {
    state = {
        challenges: []
    };
    async componentDidMount() {
        const { match, location } = this.props;
        const query = queryString.parse(location.search);
        const classID = query.classID;
        if (!classID) return;

        try {
            const { data } = await challengeService.getChallengesByClassID(
                classID
            );
            this.setState({
                challenges: data
            });
        } catch (ex) {}
    }

    columns = [
        // {
        //     title: "Grade",
        //     dataIndex: "grade"
        // },
        {
            title: "Unit",
            dataIndex: "unit"
        },
        {
            title: "Lesson",
            dataIndex: "lesson"
        },
        {
            title: "Description",
            dataIndex: "brief"
        },
        {
            title: "challengeID",
            dataIndex: "challengeID"
        },
        {
            title: "dateCreated",
            dataIndex: "dateCreated"
        },
        {
            title: "",
            render: (v, r) => {
                const { location } = this.props;
                const query = queryString.parse(location.search);

                return (
                    <div>
                        <Link
                            to={
                                "/challenge?challengeID=" +
                                r._id +
                                "&classID=" +
                                query.classID
                            }
                        >
                            <em className="fa fa-search" />
                        </Link>
                    </div>
                );
            }
        }
    ];

    render() {
        const { challenges } = this.state;
        return (
            <div
                className="container"
                style={{
                    background: "#fff",
                    borderRadius: "15px",
                    paddingTop: "15px"
                }}
            >
                <div className="row">
                    <div className="col">
                        <Table
                            dataSource={challenges}
                            columns={this.columns}
                            pagination={true}
                            bordered={true}
                            rowKey="_id"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Challenges;
