import React, { Component } from "react";
import { Link } from "react-router-dom";

class ClassBox extends Component {
    state = {};
    render() {
        const { classInfo } = this.props;
        // console.log("classInfo", classInfo);
        if (!classInfo) return "";

        return (
            <div className="classBox">
                <div className="head row">
                    <div className="lead col-sm-12">
                        Year: {classInfo.schoolYear.name}
                    </div>
                    <div className="lead col-sm-6">
                        {classInfo.grade.name.length <= 2 && "Grade"}{" "}
                        {classInfo.grade.name}
                    </div>
                    <div className="lead col-sm-6">Class {classInfo.name}</div>
                </div>
                <hr />
                <div className="body">
                    <div className="row">
                        <div className="col">Subject</div>
                        <div className="col">English</div>
                    </div>
                    <div className="row">
                        <div className="col">Student</div>
                        <div className="col">{classInfo.studentCount}</div>
                    </div>
                    <Link
                        className="btn btn-primary center enterBtn"
                        to={"/teachingMaterials?classID=" + classInfo._id}
                    >
                        Enter
                    </Link>
                </div>
            </div>
        );
    }
}

export default ClassBox;
