import _ from "lodash";
import Joi from "joi-browser";
const utils = class {
    constructor() {}
    createUser = obj => {
        if (!Array.isArray(obj.memberships)) return obj;

        obj.isSuper = obj.memberships.find(m => m.roleName === "super");
        obj.isManager = obj.memberships.find(m => m.roleName === "manager");
        obj.isTeacher = obj.memberships.find(m => m.roleName === "teacher");
        obj.isParent = obj.memberships.find(m => m.roleName === "parent");
        obj.isChild = obj.memberships.find(m => m.roleName === "child");

        obj.roles = _.uniq(obj.memberships.map(m => m.roleName)).sort();

        return obj;
    };
    createUserData = () => {
        const obj = {
            __v: "",
            _id: "",
            name: "",
            email: "",
            password: "",
            memberships: []
        };
        return obj;
    };
    userSchema = {
        _id: Joi.string().allow(""),
        name: Joi.string()
            .min(3)
            .max(50)
            .required(),
        email: Joi.string()
            .min(5)
            .max(255)
            .required()
            .email(),
        password: Joi.string()
            .max(1024)
            .when("_id", {
                is: Joi.string().min(1),
                then: Joi.allow(""),
                otherwise: Joi.string()
                    .min(8)
                    .required()
            }),
        // confirmPassword: Joi.any()
        //     .valid(Joi.ref("password"))
        //     .options({
        //         language: { any: { allowOnly: "must match password" } }
        //     }),
        memberships: Joi.array()
            .unique()
            .items(Joi.object().keys(utils.membershipSchema))
    };
    membershipSchema = {
        roleName: Joi.string()
            .min(3)
            .max(50)
            .required(),
        school: Joi.any().when("roleName", {
            is: Joi.valid("manager", "teacher", "student"),
            then: Joi.required()
        }),
        gClass: Joi.any().when("roleName", {
            is: Joi.valid("student"),
            then: Joi.required()
        }),
        parent: Joi.any().when("roleName", {
            is: Joi.valid("child"),
            then: Joi.required()
        })
    };
};
export default new utils();
