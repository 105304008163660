import "../../css/home.css";
import $ from "jquery";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Modal from "../commons/modal";
import Login from "../login";
import auth from "../../services/authService";

class Home extends Component {
    constructor(props) {
        super(props);
        this.loginRef = React.createRef();
    }
    state = {};
    componentDidMount() {
        console.log(this.props.location);
    }
    handleLoginBtnClick = () => {
        $("#loginModal").modal("show");
    };
    handleCloseBtn = () => {
        $("#loginModal").modal("hide");
    };
    handleConfirmBtn = () => {
        this.loginRef.current.handleSubmit(new Event("click"));
    };
    render() {
        if (auth.getCurrentUser()) return <Redirect to="/dashboard" />;
        return (
            <div className="container">
                <div id="pageCover">
                    <div id="logo" />
                    <div className="welcome">
                        <span className="text1">Welcome to</span>
                        <br />
                        <span className="highlight">Dr.</span>
                        <span className="highlight a">A</span>
                        <span className="highlight b">B</span>
                        <span className="highlight c">C</span>
                        <span className="text2"> Classroom</span>
                    </div>

                    <span className="text3">Please login to your account.</span>

                    <span id="loginBtn" onClick={this.handleLoginBtnClick}>
                        Login
                    </span>
                </div>
                {/* moddal */}

                <Modal
                    size="modal-lg"
                    title="Login"
                    closeBtn="Close"
                    confirmBtn="Login"
                    handleClose={this.handleCloseBtn}
                    handleConfirm={this.handleConfirmBtn}
                    id="loginModal"
                >
                    <Login ref={this.loginRef} location={this.props.location} />
                </Modal>
            </div>
        );
    }
}

export default Home;
