const Utils = class {
    constructor() {}
    getImgName = img => {
        const width = window.innerWidth;
        if (width >= 1200) return img;
        if (width >= 992) return img.replace(".png", "_lg.png");
    };
};

export default new Utils();
