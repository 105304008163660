import $ from "jquery";
import React, { Component } from "react";
import { Card } from "antd";
import schoolService from "../../services/schoolService";
import GradeBox from "../schoolComps/gradeBox";

class SchoolLanding extends Component {
    state = { schoolInfo: null };
    async componentDidMount() {
        // $("#mainContent").addClass("class");
        const { match } = this.props;
        if (!match.params.schoolCode) return;
        try {
            const { data } = await schoolService.getPublicInfo(
                match.params.schoolCode
            );
            this.setState({
                schoolInfo: data
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    componentWillUnmount() {
        $("#mainContent").removeClass("class");
    }
    render() {
        const { schoolInfo } = this.state;
        if (!schoolInfo) return "";
        return (
            <div className="container" id="schoolLanding">
                <h1 id="classroomSelection">
                    Classrooms of {schoolInfo.name}
                    <div>Click your class to continue</div>
                </h1>
                <div className="d-flex justify-content-between flex-wrap">
                    {schoolInfo.curSchoolYear.grades.map(g => {
                        return <GradeBox grade={g} key={g._id} />;
                    })}
                </div>
            </div>
        );
    }
}

export default SchoolLanding;
