import React, { Component } from "react";
import { Modal } from "antd";
import _ from "lodash";

class ChallengeCarousel_teacherTools extends Component {
    state = {
        studentPickerVisible: false,
        modalWidth: 992,
        randomName: ""
    };
    checkAnswer = () => {
        const { answers, question } = this.props;
        let flag = true;
        if (question.answers.length == 0) return false;
        if (!answers || answers.length == 0) return false;

        answers.forEach(answer => {
            if (!question.answers.includes(answer)) {
                flag = false;
                return;
            }
        });

        return flag;
    };
    getStudents = () => {
        const { classInfo } = this.props;
        let students = classInfo.ActivationCodes.map(code => {
            if (typeof code.student === "object") {
                return code.student.name;
            } else {
                return code.name;
            }
        });
        // console.log(students);
        return students;
    };
    pickStudent = () => {
        const { carouselComp } = this.props;
        let students = [...this.getStudents()];

        students = students.filter(s => {
            if (!carouselComp.state.pickedStudents.includes(s)) return s;
        });

        const shuffled = _.shuffle(students);
        carouselComp.addPickedStudents(shuffled[0]);
        if (students.length === 1) {
            carouselComp.clearPickedStudents();
        }

        return shuffled[0];
    };
    openStudentPicker = () => {
        const state = {
            studentPickerVisible: true,
            randomName: this.pickStudent()
        };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }
        this.setState(state);
    };
    closeStudentPicker = () => {
        this.setState({
            studentPickerVisible: false,
            randomName: ""
        });
    };
    render() {
        const { answers, question, toggleShowAnserFlag } = this.props;
        const { studentPickerVisible, modalWidth, randomName } = this.state;
        return (
            <React.Fragment>
                <button
                    className="btn btn-success mr-2"
                    disabled={answers.length === 0}
                    onClick={() => {
                        toggleShowAnserFlag(true);
                    }}
                >
                    Validate Answer
                </button>
                <button
                    className="btn btn-info mr-2"
                    onClick={() => {
                        this.openStudentPicker();
                    }}
                >
                    Pick Student
                </button>
                <Modal
                    footer={null}
                    maskClosable={true}
                    destroyOnClose={true}
                    title="Pick A Student"
                    visible={studentPickerVisible}
                    // width={modalWidth}
                    onCancel={() => {
                        this.closeStudentPicker();
                    }}
                >
                    <h1
                        className="text-center"
                        style={{ textTransform: "uppercase" }}
                    >
                        <i className="fa fa-user" style={{ fontSize: 100 }} />
                        <br />
                        {randomName}
                    </h1>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ChallengeCarousel_teacherTools;
