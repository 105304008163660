import queryString from "query-string";
import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";

import AssignmentTbl from "./assignmentTbl";
import ClassAssignmentResult from "./classAssignmentResult";

class AssignmentComp extends Component {
    state = {
        assignments: []
    };
    async componentDidMount() {
        const { app, match, location } = this.props;
        const query = queryString.parse(location.search);
        const classID = query.classID;
        if (!classID) return;
    }

    componentWillUnmount() {}

    render() {
        const { app, match, location } = this.props;
        const query = queryString.parse(location.search);
        const classID = query.classID;
        if (!classID) return "";

        return (
            <div
                className="container"
                style={{
                    background: "#fff",
                    borderRadius: "15px",
                    paddingTop: "15px"
                }}
            >
                <Switch>
                    <Route
                        path="/assignments/:assignmentID"
                        component={ClassAssignmentResult}
                    />
                    <Route path="/assignments" exact>
                        <AssignmentTbl classID={classID} />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default AssignmentComp;
