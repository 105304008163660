import React from "react";

const Select = ({ name, label, error, options, ...rest }) => {
    return (
        <div className="form-group">
            <label htmlFor="{name}">{label}</label>
            <select className="form-control" id={name} name={name} {...rest}>
                <option value="">- Select -</option>
                {options.map((opt, index) => (
                    <option key={index} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

export default Select;
