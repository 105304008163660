// to replace original board.jsx
import "../../css/board.css";
import $ from "jquery";
import React, { Component } from "react";
import ReactResizeDetector from "react-resize-detector";

class BoardDesign extends Component {
    state = {
        contentHeight: 0,
        audioReady: false,
        audioPlaying: false,
        titleComp: null
    };

    componentDidMount() {}
    componentWillUnmount() {}
    //custom
    onResize = (width, height) => {
        // console.log("onResize", width, height);
        this.setState({
            contentHeight: this.getContentHeight()
        });
    };
    getContentHeight = () => {
        const bodyHeight = $("#boardBody").height();
        const titleHeight = $("#boardTitleRow").height();
        const contentHeight = bodyHeight - titleHeight;
        if (isNaN(contentHeight)) return 0;
        return contentHeight;
    };
    render() {
        return (
            <div id="boardRow" className="row">
                <div id="boardCol" className="col">
                    <ReactResizeDetector
                        handleWidth
                        handleHeight
                        onResize={this.onResize}
                    />
                    {this.renderAudioPlayer()}
                    <div id="board">
                        <div id="boardBody">
                            {this.renderTitle()}
                            <div
                                id="boardContentRow"
                                className="row"
                                style={{
                                    height: this.state.contentHeight
                                }}
                            >
                                <div className="col">{this.props.children}</div>
                            </div>
                        </div>
                        {this.renderArrows()}
                    </div>
                </div>
            </div>
        );
    }
    renderTitle = () => {
        const { titleComp } = this.props;
        if (titleComp) return titleComp;
        return "";
    };
    renderArrows = () => {
        const { arrowComp } = this.props;
        if (arrowComp) {
            return arrowComp;
        }
        return "";
    };
    renderAudioPlayer = () => {
        const { audioComp } = this.props;
        if (audioComp) {
            return audioComp;
        }
        return "";
    };
}

export default BoardDesign;
