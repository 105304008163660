import React, { Component } from "react";
import { AppContext } from "../../App";

import UnitBox from "../dashComps/unitBox";
import materialService from "../../services/materialService";

class TeachingMaterials extends Component {
    state = {
        unitCount: 40,
        materials: []
    };
    async componentDidMount() {
        const { classID } = this.props;

        if (!classID) return;
        try {
            const { data } = await materialService.getByClassID(classID);
            this.setState({
                materials: data
            });
            // console.log(data);
        } catch (ex) {
            console.log(ex);
        }
    }
    componentWillUnmount() {
        const { app } = this.props;

        app.setState({
            pageTitle: ""
        });
    }
    render() {
        const { classID } = this.props;

        const { unitCount, materials } = this.state;
        const units = [];
        for (let u = 1; u <= unitCount; u++) {
            units.push(u);
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3 className="pageHead">
                            <i className="fa fa-list" aria-hidden="true" />{" "}
                            Teaching Materials
                        </h3>
                    </div>
                </div>
                <div className="row">
                    {units.map((u, index) => {
                        const unitMaterials = materials.filter(
                            m => m.unit === u
                        );
                        return (
                            <div
                                className="col-md-6 col-lg-4 col-xxl-3"
                                key={index}
                            >
                                <UnitBox
                                    unit={u}
                                    classID={classID}
                                    materials={unitMaterials}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const _TeachingMaterials = props => (
    <AppContext.Consumer>
        {app => <TeachingMaterials {...props} app={app} />}
    </AppContext.Consumer>
);

export default _TeachingMaterials;
