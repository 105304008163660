import $ from "jquery";
import React, { Component } from "react";

class CompletedChallenge extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {}
    componentDidMount() {
        console.log($("#mainContent>.bg"));
        $("#mainContent>.bg").addClass("completedChallenge");
    }
    componentWillUnmount() {
        $("#mainContent>.bg").removeClass("completedChallenge");
    }
    render() {
        return (
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    overflow: "hidden"
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <img
                        src="https://s3.amazonaws.com/drabcclassroom/portal-images/congrats.png"
                        style={{ width: "auto", cursor: "pointer" }}
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default CompletedChallenge;
