import $ from "jquery";
import React, { Component } from "react";
import queryString from "query-string";
import HtmlToReactParser from "html-to-react";

import BoardDesign from "./boardDesign";

import { AppContext } from "../../App";

import materialService from "../../services/materialService";

const html = HtmlToReactParser.Parser();

class LessonDesign_1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: null,
            audioPlaying: false,
            audioReady: false,
            audioFile: null,
            currentPageNum: 0
        };
    }
    lessons = ["phonic", "spelling", "grammar", "reading", "writing"];
    getLessonNum = lesson => {
        return this.lessons.indexOf(lesson) + 1;
    };
    async componentDidMount() {
        const { location, app } = this.props;
        const queryObj = queryString.parse(location.search);

        try {
            const { data } = await materialService.getMaterial(
                queryObj.materialID
            );
            if (data.lesson === "reading") {
                data.intro.push({ contents: [] });
            }
            this.setState({
                content: data,
                audioFile:
                    process.env.REACT_APP_RES_URL +
                    `audios/grade_${data.grade}/unit_${
                        data.unit
                    }/lesson${this.getLessonNum(
                        data.lesson
                    )}.mp3?versionId=null`
            });
            app.setState({ pageTitle: "Unit " + data.unit });
        } catch (error) {}

        $("#boardCarousel").carousel({
            ride: false,
            wrap: false,
            touch: true,
            interval: false
        });

        this.registerCarouselEvents();
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
        $("#boardCarousel").carousel("dispose");
        $("#siteNavDropDown").removeClass("d-none");
        $("#classRoomBackBtn").addClass("d-none");
    }
    registerCarouselEvents = () => {
        const { location } = this.props;
        const queryObj = queryString.parse(location.search);

        $("#boardCarousel").on("slide.bs.carousel", e => {
            this.setState({
                currentPageNum: e.to
            });

            const { content } = this.state;
            const { lesson, _id: mID, intro } = content;
            if (lesson === "reading" && e.to === intro.length - 1) {
                let readingUrl = `/reading?materialID=${mID}`;
                if (queryObj.classID)
                    readingUrl = readingUrl + "&classID=" + queryObj.classID;
                // console.log(readingUrl);
                const { history } = this.props;
                history.push(readingUrl);
            }
        });

        if (queryObj && queryObj.classID) {
            $("#siteNavDropDown").addClass("d-none");
            $("#classRoomBackBtn").removeClass("d-none");
        }
    };
    registerPlayerEvents = () => {
        const player = $("#lessonAudio")[0];

        player.onplay = () => {
            if (!this.state.audioReady) {
                this.setState({
                    audioReady: true
                });
            }
            this.setState({ audioPlaying: true });
        };
        player.onended = () => {
            // console.log("onended");
            this.setState({ audioPlaying: false });
        };
        player.onpause = () => {
            this.setState({ audioPlaying: false });
            $("#board p.active").removeClass("active");
        };
        player.onerror = () => {
            this.setState({
                audioReady: false
            });
        };

        this.playTitle();
    };
    navLeft = () => {
        $("#boardCarousel").carousel("prev");
    };
    navRight = () => {
        $("#boardCarousel").carousel("next");
    };
    stopPlayer = () => {
        const player = $("#lessonAudio")[0];
        player.pause();
    };
    playAudio = ({ audioStart, audioEnd }) => {
        console.log({ audioStart, audioEnd });
        if (!this.state.audioReady) return;
        if (!audioStart) audioStart = 0;
        if (!audioEnd) audioEnd = 0;

        if (audioStart === 0 && audioEnd === 0) return;

        $("#board p.active").removeClass("active");

        const { audioFile } = this.state;
        let src = audioFile + `#t=${audioStart},${audioEnd}`;
        if (audioStart > 0 && audioEnd === 0)
            src = audioFile + `#t=${audioStart}`;

        const player = $("#lessonAudio")[0];

        $("#lessonAudio").attr("src", src);

        player.play();
    };
    playTitle = () => {
        const player = $("#lessonAudio")[0];
        const { content } = this.state;
        const { mTitle, introTitle, lesson } = content;
        let titleObj = mTitle;
        if (lesson === "reading") titleObj = introTitle;

        if (titleObj.audioEnd > 0) player.play();
    };

    getTitle = () => {
        const { content } = this.state;
        const { mTitle, introTitle, lesson } = content;
        let titleObj = mTitle;
        if (lesson === "reading") titleObj = introTitle;

        return titleObj;
    };
    render() {
        const { content } = this.state;
        if (!content) return "";
        return (
            <BoardDesign
                titleComp={this.renderTitle()}
                audioComp={this.renderAudioPlayer()}
                arrowComp={this.renderArrows()}
            >
                <div
                    id="boardCarousel"
                    className="slide"
                    style={{ height: "100%" }}
                >
                    <div className="carousel-inner" style={{ height: "100%" }}>
                        {this.renderPage()}
                    </div>
                </div>
            </BoardDesign>
        );
    }

    renderPage = () => {
        const { content } = this.state;
        const { mPages, intro, lesson } = content;
        let data = mPages;
        if (lesson === "reading") data = intro;

        return data.map((page, pIndex) => {
            return (
                <div
                    className={
                        pIndex === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={pIndex}
                    data-index={pIndex}
                    style={{
                        height: "100%"
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignSelf: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%"
                            }}
                        >
                            {this.renderContent(page, pIndex)}
                        </div>
                    </div>
                </div>
            );
        });
    };

    renderContent = (page, pIndex) => {
        const { audioReady, content } = this.state;
        const { mPages, intro, lesson } = content;
        let data = mPages;
        if (lesson === "reading") data = intro;

        // console.log("content", content);

        return page.contents.map((content, cIndex) => {
            return (
                <p
                    id={`p${pIndex}_${cIndex}`}
                    key={cIndex}
                    onClick={() => {
                        this.playAudio(content);
                        if (audioReady) {
                            $(`#p${pIndex}_${cIndex}`).addClass("active");
                        }
                    }}
                    style={{
                        cursor: audioReady ? "pointer" : "default"
                    }}
                >
                    {html.parse(content.content)}
                </p>
            );
        });
    };
    renderAudioPlayer = () => {
        const { content, audioFile } = this.state;
        const { mTitle, introTitle, lesson } = content;
        let titleObj = mTitle;
        if (lesson === "reading") titleObj = introTitle;
        return (
            <audio
                id="lessonAudio"
                style={{ display: "none" }}
                src={
                    audioFile + `#t=${titleObj.audioStart},${titleObj.audioEnd}`
                }
                onLoadedData={() => {
                    this.registerPlayerEvents();
                }}
            />
        );
    };
    renderArrows = () => {
        const { currentPageNum, content } = this.state;
        const { mPages, intro, lesson } = content;
        let data = mPages;
        if (lesson === "reading") data = intro;
        return (
            <React.Fragment>
                {currentPageNum !== 0 && (
                    <span id="leftBtn" onClick={this.navLeft} />
                )}
                {currentPageNum !== data.length - 1 && (
                    <span id="rightBtn" onClick={this.navRight} />
                )}
            </React.Fragment>
        );
    };
    renderTitle = () => {
        const { content, audioPlaying, audioReady } = this.state;
        const { mTitle, introTitle, lesson } = content;
        let titleObj = mTitle;
        if (lesson === "reading") titleObj = introTitle;
        if (!titleObj.title) return "";
        return (
            <div className="row" id="boardTitleRow">
                <div className="col-2">
                    <img
                        id="drIcon"
                        src={
                            process.env.REACT_APP_RES_URL + "images/drIcon.png"
                        }
                    />
                </div>
                <div className="col-8 titleWrap">
                    <p
                        className="title active"
                        onClick={() => {
                            this.playAudio(titleObj);
                            $("#board .title").addClass("active");
                        }}
                        style={{
                            cursor: audioReady ? "pointer" : "default"
                        }}
                    >
                        {titleObj.title}
                    </p>
                </div>
                <div className="col-2">
                    {audioReady && (
                        <img
                            id="soundIcon"
                            src={
                                audioPlaying
                                    ? process.env.REACT_APP_RES_URL +
                                      "images/sound.gif"
                                    : process.env.REACT_APP_RES_URL +
                                      "images/soundIcon_m.png"
                            }
                            onClick={() => {
                                this.stopPlayer();
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };
}
const LessonDesign_1_ = props => {
    return (
        <AppContext.Consumer>
            {app => <LessonDesign_1 {...props} app={app} />}
        </AppContext.Consumer>
    );
};

export default LessonDesign_1_;
