import "../../css/dashboard.css";
import React, { Component } from "react";
import _ from "lodash";
import ClassBox from "../dashComps/classBox";
import { AppContext } from "../../App";
import schoolService from "../../services/schoolService";

class DashBoard extends Component {
    state = {
        teacherInfo: null,
        schoolInfo: null,
        gClasses: []
    };

    async componentDidMount() {
        const { app, match, history } = this.props;
        const { curUser } = app.state;
        // console.log(curUser);
        if (!curUser) return;

        try {
            if (curUser.isTeacher && !curUser.isManager) {
                const { data: teacherInfo } = await schoolService.getTeacher(
                    curUser._id
                );
                this.setState({
                    teacherInfo,
                    gClasses: this.getClassesByTeacherInfo(teacherInfo)
                });
            } else {
                const schoolID =
                    (curUser.isSuper && curUser.isSuper.school) ||
                    (curUser.isManager && curUser.isManager.school);

                console.log(schoolID);
                const { data: schooInfo } = await schoolService.getInfo(
                    schoolID
                );
                this.setState({
                    schooInfo,
                    gClasses: this.getClassesBySchoolInfo(schooInfo)
                });
            }
        } catch (ex) {
            console.log(ex);
        }

        app.setState({
            pageTitle: "Dashboard"
        });
    }
    getClassesByTeacherInfo = info => {
        if (!info) return [];
        const classes = [];
        info.gClasses.forEach(c => {
            classes.push(c);
        });
        return classes;
    };
    getClassesBySchoolInfo = info => {
        if (!info) return [];
        // console.log(info);

        const classes = [];
        info.curSchoolYear.grades.forEach(g => {
            const grade = { ...g };
            delete grade.gClasses;
            const sy = { ...info.curSchoolYear };
            delete sy.grades;
            g.gClasses.forEach(c => {
                c.grade = grade;
                c.schoolYear = sy;
                c.studentCount = c.ActivationCodes.length;
                classes.push(c);
            });
        });

        return classes;
    };
    componentWillUnmount() {
        const { app } = this.props;

        app.setState({
            pageTitle: ""
        });
    }
    render() {
        const { gClasses } = this.state;
        const sortedGClasses = this.sortClass(gClasses);
        console.log(gClasses);

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3 className="pageHead">
                                <i
                                    className="fa fa-sitemap"
                                    aria-hidden="true"
                                />{" "}
                                Your Classes
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        {gClasses.map((c, index) => {
                            return (
                                <div className="col-lg-3 col-md-6" key={index}>
                                    <ClassBox classInfo={c} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
    sortClass = gClasses => {
        gClasses = _.orderBy(
            gClasses,
            ["schoolYear.sort", , "schoolYear._id", "grade.sort", "grade._id"],
            "asc"
        );

        return gClasses;
    };
}

const _DashBoard = props => (
    <AppContext.Consumer>
        {app => <DashBoard {...props} app={app} />}
    </AppContext.Consumer>
);

export default _DashBoard;
