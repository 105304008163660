import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import ChallengeCarousel_teacherTools from "./challengeCarousel_teacherTools";
import ChallengeOpt from "./challengeOpt";
import moment from "moment";

class ChallengeCarousel_card extends Component {
    state = {
        answers: [],
        showAnserFlag: false,
        blinkAnswer: false,
        blinkTimer: null,
        timeSpend: 0,
        timeBegin: null
    };

    getOptDesign = () => {
        let design = "box";

        const { question: q } = this.props;

        const optLength = 10;

        const isOptLong = q.options.some(opt => opt.length > optLength);

        if (isOptLong) design = "list";

        return design;
    };
    pickOpt = opt => {
        let answers = [...this.state.answers];
        const { question: q, index, challenge } = this.props;
        if (q.answers.length > 1) {
            if (answers.includes(opt)) {
                answers = answers.filter(a => a != opt);
            }
        } else {
            answers = [];
        }
        answers.push(opt);
        this.setState({ answers });
    };
    ifLast = () => {
        const { question: q, index, challenge } = this.props;
        if (index === challenge.questions.length - 1) return true;

        return false;
    };
    ifShowTeacherTools = () => {
        const { challenge, challengeID, assignmentID, curUser } = this.props;
        if (
            curUser &&
            (curUser.isSuper || curUser.isManager || curUser.isTeacher)
        )
            return true;

        return false;
    };
    toggleShowAnserFlag = val => {
        if (!val) val = !this.state.showAnserFlag;
        this.setState({ showAnserFlag: val });
    };

    componentDidMount() {
        const {
            question: q,
            index,
            challenge,
            curUser,
            classInfo,
            completeChallenge,
            ifChallengeCompleted,
            carouselComp
        } = this.props;

        const blinkTimer = setInterval(() => {
            if (this.state.showAnserFlag) {
                this.setState({ blinkAnswer: !this.state.blinkAnswer });
            } else {
                this.setState({ blinkAnswer: false });
            }
        }, 800);
        this.setState({ blinkTimer, timeBegin: moment() });

        // console.log("question", q);
    }
    componentWillUnmount() {
        window.clearTimeout(this.state.blinkTimer);
    }
    render() {
        const {
            question: q,
            index,
            challenge,
            curUser,
            classInfo,
            completeChallenge,
            ifChallengeCompleted,
            carouselComp
        } = this.props;
        const { answers, showAnserFlag, blinkAnswer } = this.state;
        let itemCls = "carousel-item";

        let opts = q.options;
        // opts = _.shuffle(q.options);

        if (index === 0) itemCls = itemCls + " active";
        const optDesgin = this.getOptDesign();

        return (
            <div className={itemCls}>
                <div
                    style={{
                        margin: "auto",
                        textAlign: "center",
                        fontFamily: "drabc"
                    }}
                >
                    {q.paragraph && (
                        <p
                            style={{ marginTop: "20px", fontSize: "16pt" }}
                            className="text-info"
                        >
                            <i
                                className="fa fa-lightbulb-o"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                            />
                            {q.paragraph}
                        </p>
                    )}
                    <h2 style={{ marginTop: "8px" }}>{q.question}</h2>

                    <div id="challengeCarousel_optWrap">
                        {opts.map((opt, index) => {
                            return (
                                <ChallengeOpt
                                    question={q}
                                    key={index}
                                    index={index}
                                    option={opt}
                                    answers={answers}
                                    onClick={() => {
                                        this.pickOpt(opt);
                                    }}
                                    optDesgin={optDesgin}
                                    showAnserFlag={showAnserFlag}
                                    blinkAnswer={blinkAnswer}
                                />
                            );
                        })}
                    </div>

                    <div className="challengeCarousel_btnGroups">
                        {!this.ifLast() && (
                            <button
                                className="btn btn-primary mr-2"
                                disabled={this.state.answers.length === 0}
                                onClick={() => {
                                    $("#challengeCarousel").carousel("next");
                                }}
                            >
                                Next Question
                            </button>
                        )}

                        {this.ifLast() && this.ifShowTeacherTools() && (
                            <button
                                className="btn btn-primary mr-2"
                                disabled={!ifChallengeCompleted()}
                                onClick={() => {
                                    completeChallenge(false);
                                }}
                            >
                                Complete
                            </button>
                        )}

                        {this.ifLast() && !this.ifShowTeacherTools() && (
                            <button
                                className="btn btn-primary mr-2"
                                disabled={this.state.answers.length === 0}
                                onClick={() => {
                                    completeChallenge(true);
                                }}
                            >
                                Submit Answers
                            </button>
                        )}

                        {this.ifShowTeacherTools() && (
                            <ChallengeCarousel_teacherTools
                                classInfo={classInfo}
                                answers={this.state.answers}
                                question={q}
                                toggleShowAnserFlag={this.toggleShowAnserFlag}
                                carouselComp={carouselComp}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ChallengeCarousel_card;
