import $ from "jquery";
import queryString from "query-string";

import React, { Component } from "react";

import BookDesign from "./bookDesign";
import materialService from "../../services/materialService";
import { AppContext } from "../../App";

import HtmlToReactParser from "html-to-react";
const html = HtmlToReactParser.Parser();

class BookDesign_reading extends Component {
    state = {
        content: null,
        actiPageIndex: 0,
        audioPlaying: false,
        audioReady: false,
        audioFile: null
    };
    lessons = ["phonic", "spelling", "grammar", "reading", "writing"];
    getLessonNum = lesson => {
        return this.lessons.indexOf(lesson) + 1;
    };
    async componentDidMount() {
        const { location, app } = this.props;
        const queryObj = queryString.parse(location.search);
        try {
            const { data } = await materialService.getMaterial(
                queryObj.materialID
            );
            this.setState({
                content: data,
                audioFile:
                    process.env.REACT_APP_RES_URL +
                    `audios/grade_${data.grade}/unit_${
                        data.unit
                    }/lesson${this.getLessonNum(data.lesson)}.mp3`
            });
            app.setState({ pageTitle: "Unit " + data.unit });
        } catch (error) {}

        $("#bookCarousel").carousel({
            ride: false,
            wrap: false,
            touch: true,
            interval: false
        });

        this.registerCarouselEvents();
        this.registerPlayerEvents();

        if (queryObj && queryObj.classID) {
            $("#classRoomBackBtn").removeClass("d-none");
            $("#siteNavDropDown").addClass("d-none");
        }
    }
    componentWillUnmount() {
        const { location, app } = this.props;
        app.setState({
            pageTitle: ""
        });
        $("#bookCarousel").carousel("dispose");
        $("#siteNavDropDown").removeClass("d-none");
        $("#classRoomBackBtn").addClass("d-none");
    }
    registerCarouselEvents = () => {
        $("#bookCarousel").on("slide.bs.carousel", e => {
            this.setState({
                actiPageIndex: e.to
            });
        });
    };
    registerPlayerEvents = () => {
        const player = $("#lessonAudio")[0];
        player.oncanplay = () => {
            this.setState({
                audioReady: true
            });
        };
        player.onplay = () => {
            // console.log("onplay");
            this.setState({ audioPlaying: true });
        };
        player.onended = () => {
            // console.log("onended");
            this.setState({ audioPlaying: false });
        };
        player.onpause = () => {
            this.setState({ audioPlaying: false });
        };
        player.onerror = () => {};
    };

    navLeft = () => {
        const { content } = this.state;
        const { history } = this.props;
        $("#bookCarousel").carousel("prev");
        if (this.hasReadingIntro(content)) {
            const curIndex = $("#bookCarousel .carousel-item.active").index(
                "#bookCarousel .carousel-item"
            );
            if (curIndex === 0) {
                history.goBack();
            }
        }
    };
    navRight = () => {
        $("#bookCarousel").carousel("next");
    };
    stopPlayer = () => {
        const player = $("#lessonAudio")[0];
        player.pause();
    };
    playAudio = ({ audioStart, audioEnd }) => {
        console.log({ audioStart, audioEnd });
        if (!this.state.audioReady) return;
        if (!audioStart) audioStart = 0;
        if (!audioEnd) audioEnd = 0;

        if (audioStart === 0 && audioEnd === 0) return;

        $("#book p.active").removeClass("active");

        const { audioFile } = this.state;
        let src = audioFile + `#t=${audioStart},${audioEnd}`;
        if (audioStart > 0 && audioEnd === 0)
            src = audioFile + `#t=${audioStart}`;

        const player = $("#lessonAudio")[0];

        $("#lessonAudio").attr("src", src);

        player.play();
    };
    hasReadingIntro = material => {
        if (
            material &&
            material.lesson === "reading" &&
            material.intro &&
            Array.isArray(material.intro) &&
            material.intro.length > 0 &&
            material.intro[0].contents.length > 0
        )
            return true;

        return false;
    };
    render() {
        const { content } = this.state;
        if (!content) return "";
        return (
            <BookDesign audioComp={this.renderAudioPlayer()}>
                {this.renderReadingIcon()}
                {this.renderTitle()}
                {this.renderSounIcon()}
                <div id="bookContentRow">
                    <div className="slide" id="bookCarousel">
                        <div className="carousel-inner">
                            {this.renderPage()}
                        </div>
                    </div>
                </div>
            </BookDesign>
        );
    }
    renderPage = () => {
        const { audioReady, content } = this.state;
        const { mPages } = content;
        let data = mPages;
        return data.map((page, pIndex) => {
            return (
                <div
                    className={
                        pIndex === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={pIndex}
                    style={{
                        height: "100%"
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignSelf: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}
                        >
                            {page.contents.map((content, cIndex) => {
                                return (
                                    <p
                                        id={`p${pIndex}_${cIndex}`}
                                        key={cIndex}
                                        onClick={() => {
                                            this.playAudio(content);
                                            if (audioReady) {
                                                $(
                                                    `#p${pIndex}_${cIndex}`
                                                ).addClass("active");
                                            }
                                        }}
                                        style={{
                                            cursor: audioReady
                                                ? "pointer"
                                                : "default"
                                        }}
                                    >
                                        {html.parse(content.content)}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        });
    };
    renderTitle = () => {
        const { content, audioReady } = this.state;
        const { mTitle, lesson } = content;

        return (
            <div id="bookTitleRow">
                <div id="bookTitle">
                    <p
                        className="title active"
                        onClick={() => {
                            this.playAudio(mTitle);

                            $("#board .title").addClass("active");
                        }}
                        style={{
                            cursor: audioReady ? "pointer" : "default"
                        }}
                    >
                        {mTitle.title}
                    </p>
                    {lesson && <div className="subTitle">- The {lesson} -</div>}
                </div>
            </div>
        );
    };

    renderSounIcon = () => {
        const { audioPlaying, audioReady } = this.state;
        return (
            <div id="bookSoundIcon">
                {audioReady && (
                    <img
                        id="soundIcon"
                        src={
                            audioPlaying
                                ? process.env.REACT_APP_RES_URL +
                                  "images/sound.gif"
                                : process.env.REACT_APP_RES_URL +
                                  "images/soundIcon_m.png"
                        }
                        onClick={() => {
                            this.stopPlayer();
                        }}
                    />
                )}
            </div>
        );
    };
    renderAudioPlayer = () => {
        const { audioFile, content } = this.state;
        const { mTitle } = content;
        return (
            <audio
                id="lessonAudio"
                style={{ display: "none" }}
                src={audioFile + `#t=${mTitle.audioStart},${mTitle.audioEnd}`}
                autoPlay={mTitle.audioEnd > 0 ? true : false}
            />
        );
    };
    renderReadingIcon = () => {
        return (
            <img
                id="readingIcon"
                src={process.env.REACT_APP_RES_URL + "images/readingIcon.png"}
            />
        );
    };
}

const BookDesign_reading_ = props => (
    <AppContext.Consumer>
        {app => <BookDesign_reading {...props} app={app} />}
    </AppContext.Consumer>
);
export default BookDesign_reading_;
