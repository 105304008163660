import $ from "jquery";
import ReactResizeDetector from "react-resize-detector";
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";

import Navigation from "./components/navComps/Navigation";
import MainContent from "./components/mainContent";
import utils from "./utils/utils";
import SiteFooter from "./components/footer";

import auth from "./services/authService";
import schoolService from "./services/schoolService";
import httpService from "./services/httpService";
import userService from "./services/userService";
import kidService from "./services/kidService";
import userUtils from "./utils/userUtils";
import { Switch } from "react-router-dom";
import Res from "./components/res";

export const AppContext = React.createContext();

function getAppContext(comp) {
    return comp;
}

class App extends Component {
    constructor(props) {
        super(props);
        console.log(process.env.NODE_ENV);
        if (
            window.location.protocol === "http:" &&
            process.env.NODE_ENV === "production"
        ) {
            window.location.href = window.location.href.replace(
                "http://",
                "https://"
            );
        }
        this.state = {
            appWidth: null,
            appHeight: null,
            curUser: auth.getCurrentUser(),
            schoolID: null,
            loaded: false,
            pageTitle: ""
        };
    }
    async componentDidMount() {
        const { curUser: loadedUser } = this.state;
        if (loadedUser) {
            try {
                const { data } = await userService.getUser(loadedUser._id);
                const curUser = userUtils.createUser(data);
                let schoolID =
                    curUser && curUser.isSuper && curUser.isSuper.school
                        ? curUser.isSuper.school
                        : "5c2a4bf75ce05719b8e36a90";
                if (curUser && curUser.isTeacher)
                    schoolID = curUser.isTeacher.school;
                if (curUser && curUser.isManager)
                    schoolID = curUser.isManager.school;
                httpService.setSchoolIDHeader(schoolID);

                this.setState({
                    curUser,
                    schoolID,
                    loaded: true
                });
            } catch (ex) {
                console.log(ex);
                // auth.logout();
                // window.location.reload();
            }
        } else {
            this.setState({ loaded: true });
        }
    }
    onResize = (width, height) => {
        // console.log(width, height);
        // this.setState({
        //     appWidth: width,
        //     appHeight: height
        // });
    };
    render() {
        // console.log(process.env.NODE_ENV);
        const { curUser, loaded } = this.state;
        if (!loaded) return "";
        let mainBodyCls = "container-fluid";
        if (process.env.NODE_ENV === "development")
            mainBodyCls += " development";
        if (process.env.NODE_ENV === "production") mainBodyCls += " production";
        if (process.env.NODE_ENV === "sandbox") mainBodyCls += " sandbox";
        return (
            <AppContext.Provider value={getAppContext(this)}>
                <Res />
                <ToastContainer />
                <ReactResizeDetector
                    handleWidth
                    handleHeight
                    onResize={this.onResize}
                />

                <div className={mainBodyCls} id="mainBody">
                    <div className="row">
                        <div className="col">
                            <Switch>
                                <Navigation />
                            </Switch>
                            <MainContent />
                            <SiteFooter />
                        </div>
                    </div>
                </div>
            </AppContext.Provider>
        );
    }
}

export default App;
