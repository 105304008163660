import "../../css/alphabet.css";
import React, { Component } from "react";
import $ from "jquery";
import queryString from "query-string";

import BoardDesign from "./boardDesign";

import alphabetService from "../../services/alphabetService";

class AlphabetComp extends Component {
    state = {
        letterObj: null,
        currentPageNum: 0
    };
    async componentDidMount() {
        const { location } = this.props;
        const queryObj = queryString.parse(location.search);

        if (queryObj.letter) {
            try {
                const {
                    data: letterObj
                } = await alphabetService.getAlphabetByletter(queryObj.letter);
                console.log(letterObj);
                this.setState({ letterObj });
            } catch (ex) {
                console.log(ex);
            }
        }

        $("#boardCarousel").carousel({
            ride: false,
            wrap: false,
            touch: true,
            interval: false
        });

        this.registerCarouselEvents();
    }

    registerCarouselEvents = () => {
        const { letterObj } = this.state;

        if (!letterObj) return;

        $("#boardCarousel").on("slide.bs.carousel", e => {
            this.setState({
                currentPageNum: e.to
            });
        });

        $("#boardCarousel").on("slid.bs.carousel", e => {
            $(".upperSVG, .lowerSVG").html("");
            $(".upperSVG, .lowerSVG").unbind("click");
            const slideIndex = e.to;

            const upperSVG = $(
                ".carousel-item[data-index=" + slideIndex + "]"
            ).find(".upperSVG");
            if (upperSVG.length > 0) {
                let html = letterObj.upperSVG;
                html = this.processSVG(html, true);

                $(upperSVG).html(html);
                this.bindSVGAnimation(upperSVG);
            }

            const lowerSVG = $(
                ".carousel-item[data-index=" + slideIndex + "]"
            ).find(".lowerSVG");
            if (lowerSVG.length > 0) {
                let html = letterObj.lowerSVG;
                html = this.processSVG(html);
                $(lowerSVG).html(html);
                this.bindSVGAnimation(lowerSVG, "lower");
            }
        });
    };
    bindSVGAnimation = (SVG, letterCase) => {
        const comp = this;
        const { letterObj } = this.state;
        $(SVG).on("click", "svg", function() {
            const classNames = $(this)
                .find("g >*")
                .not(".cover")
                .attr("class");
            if (!(classNames && classNames.includes("animate"))) {
                $(this)
                    .find("g >*")
                    .not(".cover")
                    .each(function() {
                        $(this).css("stroke", "none");
                    });
            }
            $(this)
                .find("g>*")
                .not(".cover")
                .each(function(index) {
                    if (
                        index ===
                        $(SVG)
                            .find("g>*")
                            .not(".cover").length -
                            1
                    ) {
                        clearTimeout(window.SVGTimeer);
                        window.SVGTimeer = setTimeout(() => {
                            $(SVG).text("");
                            let html = letterObj.upperSVG;
                            if (letterCase && letterCase == "lower") {
                                html = letterObj.lowerSVG;
                                html = comp.processSVG(html);
                            } else {
                                html = comp.processSVG(html, true);
                            }

                            $(SVG).html(html);
                        }, 1200);
                    }

                    const clsName = "animate" + (index + 1);
                    if (!$(this).hasClass(clsName)) {
                        $(this).addClass(clsName);
                        return false;
                    }
                });
        });
    };
    navLeft = () => {
        $("#boardCarousel").carousel("prev");
    };
    navRight = () => {
        $("#boardCarousel").carousel("next");
    };
    processSVG = (svg, isUpper) => {
        svg = svg
            .replace(/stroke: #000/g, "stroke:#fff")
            .replace(/style="fill: #fff"/g, 'style="fill: #fff" class="cover"');
        if (isUpper) {
            svg = svg
                .replace(/id="clip-path"/g, 'id="clip-path-upper"')
                .replace(/#clip-path/g, "#clip-path-upper");
        } else {
            svg = svg
                .replace(/id="clip-path"/g, 'id="clip-path-lower"')
                .replace(/#clip-path/g, "#clip-path-lower");
        }
        return svg;
    };
    render() {
        const { letterObj } = this.state;
        if (!letterObj) return "";
        return (
            <BoardDesign
                arrowComp={this.renderArrows()}
                titleComp={this.renderTitle()}
            >
                <div
                    id="boardCarousel"
                    className="slide"
                    style={{ height: "100%" }}
                >
                    <div className="carousel-inner" style={{ height: "100%" }}>
                        {this.renderCarousel()}
                    </div>
                </div>
            </BoardDesign>
        );
    }
    renderArrows = () => {
        const currentPageNum = this.state.currentPageNum;
        return (
            <React.Fragment>
                {currentPageNum > 0 && (
                    <span id="leftBtn" onClick={this.navLeft} />
                )}
                {currentPageNum < 3 && (
                    <span id="rightBtn" onClick={this.navRight} />
                )}
            </React.Fragment>
        );
    };
    renderCarousel = () => {
        const pageIndexs = [0, 1, 2, 3];

        return pageIndexs.map((page, pIndex) => {
            return (
                <div
                    className={
                        pIndex === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={pIndex}
                    data-index={pIndex}
                    style={{
                        height: "100%"
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignSelf: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%"
                            }}
                        >
                            {this.renderPageContent(pIndex)}
                        </div>
                    </div>
                </div>
            );
        });
    };

    renderPageContent = pIndex => {
        const { letterObj } = this.state;
        if (pIndex === 0) {
            return (
                <div
                    className="row"
                    style={{
                        textAlign: "center",
                        fontSize: "108pt",
                        fontFamily: "Poppins",
                        lineHeight: "1"
                    }}
                >
                    <div className="col-md-6">
                        <span
                            className="alp_uppercase"
                            style={{
                                textTransform: "uppercase",
                                marginRight: "30px"
                            }}
                        >
                            {letterObj.letter.toUpperCase()}
                        </span>
                        <span
                            className="alp_lowercase"
                            style={{
                                textTransform: "lowercase"
                            }}
                        >
                            {letterObj.letter.toLowerCase()}
                        </span>
                        <div
                            className="alp_wording"
                            style={{ fontSize: "36pt", marginTop: "20px" }}
                        >
                            {letterObj.word}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img
                            className="alp_thumb"
                            style={{ maxWidth: "100%" }}
                            src={letterObj.image}
                        />
                    </div>
                </div>
            );
        }

        if (pIndex === 1) {
            return (
                <div className="row" style={{ textAlign: "center" }}>
                    <div className="col-md-12">
                        <div
                            className="letterLines"
                            style={{ transform: "scale(1.5)" }}
                        >
                            <div className="mid" />
                            <div
                                className={
                                    "upperSVG " +
                                    "letter_upper_" +
                                    letterObj.letter
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <span
                            className="btn btn-primary"
                            onClick={() => {
                                $(".carousel-item[data-index=1]")
                                    .find(".upperSVG svg")
                                    .click();
                            }}
                        >
                            Write
                        </span>
                    </div> */}
                </div>
            );
        }
        if (pIndex === 2) {
            return (
                <div className="row" style={{ textAlign: "center" }}>
                    <div className="col-md-12">
                        <div
                            className="letterLines"
                            style={{ transform: "scale(1.5)" }}
                        >
                            <div className="mid" />
                            <div
                                className={
                                    "lowerSVG " +
                                    "letter_lower_" +
                                    letterObj.letter
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <span
                            className="btn btn-primary"
                            onClick={() => {
                                $(".carousel-item[data-index=2]")
                                    .find(".lowerSVG svg")
                                    .click();
                            }}
                        >
                            Write
                        </span>
                    </div> */}
                </div>
            );
        }
        if (pIndex === 3) {
            return (
                <div className="row" style={{ textAlign: "center" }}>
                    <div className="col-md-12">
                        <div
                            className="letterLines"
                            style={{ transform: "scale(1.5)" }}
                        >
                            <div className="mid" />
                            <span
                                className={
                                    "upperSVG " +
                                    "letter_upper_" +
                                    letterObj.letter
                                }
                            />
                            <div
                                className={
                                    "lowerSVG " +
                                    "letter_lower_" +
                                    letterObj.letter
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-6" /> */}
                </div>
            );
        }
    };
    renderTitle = () => {
        const { letterObj } = this.state;
        const title = "Learning Letter " + letterObj.letter.toUpperCase();
        return (
            <div className="row" id="boardTitleRow">
                <div className="col-2">
                    <img
                        id="drIcon"
                        src={
                            process.env.REACT_APP_RES_URL + "images/drIcon.png"
                        }
                    />
                </div>
                <div className="col-8 titleWrap">
                    <p className="title active">{title}</p>
                </div>
            </div>
        );
    };
}

export default AlphabetComp;
