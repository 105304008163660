import http from "./httpService";
import jwtDecode from "jwt-decode";
import userUtils from "../utils/userUtils";

const apiEndpoint = "/auth";
const tokenKey = "token";

http.setJwt(getJwt());

async function login(email, password, deviceName) {
    const { data: jwt } = await http.post(apiEndpoint, {
        email,
        password,
        deviceName
    });
    localStorage.setItem(tokenKey, jwt);
}

async function classRoomlogin(email, password, deviceName) {
    const { data: jwt } = await http.post(apiEndpoint, {
        email,
        password,
        deviceName,
        app: "classroom"
    });
    localStorage.setItem(tokenKey, jwt);
}

function logout() {
    localStorage.removeItem(tokenKey);
}

function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        let user = jwtDecode(jwt);
        user = userUtils.createUser(user);
        return user;
    } catch (ex) {
        return null;
    }
}

function getJwt() {
    return localStorage.getItem(tokenKey);
}

export default {
    login,
    classRoomlogin,
    logout,
    loginWithJwt,
    getCurrentUser,
    getJwt
};
