import http from "./httpService";

const apiEndpoint = "/schools";
const yearEndPoint = "/schoolYears";
const gradeEndPoint = "/schoolGrades";
const classEndPoint = "/schoolClasses";
const managerEndPoint = "/schoolManagers";
const teacherEndPoint = "/schoolTeachers";
const studentEndPoint = "/schoolStudents";
const activationCodeEndPoint = "/activationCodes";

const Utils = class {
    constructor() {}
    //super
    createSchool = async data => {
        return await http.post(apiEndpoint, data);
    };
    getSchools = async () => {
        return await http.get(apiEndpoint);
    };

    updateSchool = async data => {
        return await http.put(apiEndpoint + "/" + data._id, data);
    };
    getSchool = async schoolID => {
        return await http.get(apiEndpoint + "/" + schoolID);
    };

    deleteSchool = async schoolID => {
        return await http.delete(apiEndpoint + "/" + schoolID);
    };

    createYear = async data => {
        return await http.post(yearEndPoint, data);
    };

    updateYear = async data => {
        return await http.put(yearEndPoint + "/" + data._id, data);
    };
    getYears = async () => {
        return await http.get(yearEndPoint);
    };
    getYear = async schoolYearID => {
        return await http.get(yearEndPoint + "/" + schoolYearID);
    };
    deleteYear = async schoolYearID => {
        return await http.delete(yearEndPoint + "/" + schoolYearID);
    };
    createGrade = async data => {
        return await http.post(gradeEndPoint, data);
    };
    updateGrade = async data => {
        return await http.put(gradeEndPoint + "/" + data._id, data);
    };
    deleteGrade = async (gradeID, schoolYear) => {
        return await http.delete(gradeEndPoint + "/" + gradeID, {
            data: schoolYear
        });
    };
    deleteClass = async classID => {
        return await http.delete(classEndPoint + "/" + classID);
    };

    createClass = async data => {
        return await http.post(classEndPoint, data);
    };

    updateClass = async data => {
        return await http.put(classEndPoint + "/" + data._id, data);
    };

    getClass = async classID => {
        http.setClassIDHeader(classID);
        return await http.get(classEndPoint + "/" + classID);
    };

    getManagers = async () => {
        return await http.get(managerEndPoint);
    };
    assignManager = async email => {
        return await http.post(managerEndPoint + "/" + email);
    };

    deleteManager = async data => {
        return await http.delete(managerEndPoint, { data: data });
    };

    getTeachers = async () => {
        return await http.get(teacherEndPoint);
    };
    getTeacher = async teacherID => {
        return await http.get(teacherEndPoint + "/" + teacherID);
    };
    createTeacher = async data => {
        return await http.post(teacherEndPoint, data);
    };

    updateTeacher = async data => {
        return await http.put(teacherEndPoint + "/" + data._id, data);
    };

    inviteTeacher = async email => {
        return await http.post(teacherEndPoint + "/" + email);
    };

    deleteTeacher = async teacherID => {
        return await http.delete(teacherEndPoint + "/" + teacherID);
    };

    acceptInvite = async data => {
        return await http.post(teacherEndPoint + "/accept", data);
    };
    getStudents = async () => {
        return await http.get(studentEndPoint);
    };

    getActivationCode = async (classID, codeID) => {
        return await http.get(`${activationCodeEndPoint}/${classID}/${codeID}`);
    };
    updateActivationCode = async data => {
        return await http.put(
            `${activationCodeEndPoint}/${data.classID}/${data._id}`,
            data
        );
    };

    getInfo = async schoolID => {
        return await http.get(`${apiEndpoint}/info/${schoolID}`);
    };
    //public

    getPublicInfo = async schoolCode => {
        return await http.get(`${apiEndpoint}/public/info/${schoolCode}`);
    };
    getPublicClassInfo = async classID => {
        return await http.get(`${classEndPoint}/public/info/${classID}`);
    };
};

export default new Utils();
