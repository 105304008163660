import http from "./httpService";
import auth from "./authService";
import { async } from "q";
const endpoint = "/challengeResults";

http.setJwt(auth.getJwt());

const Utils = class {
    createResult = async result => {
        return await http.post(endpoint, result);
    };

    getAssignmentResults = async assignmentID => {
        return await http.get(endpoint + "/assignment/" + assignmentID);
    };
    getByChallengeID = async challengeID => {
        return await http.get(endpoint + "/byChallengeID/" + challengeID);
    };
    getByStudentID = async studentID => {
        //no detail
        return await http.get(endpoint + "/byStudentID/" + studentID);
    };
};

export default new Utils();
